import { SET_LOCATION } from "../actions/userLocationActions"

export const userLocationReducer = (state, action) => {
    switch(action.type) {
        case SET_LOCATION:
            localStorage.setItem("selectedUserAddress", JSON.stringify(action.payload))
            return {
                addressLine1: action.payload.addressLine1,
                addressLine2: action.payload.addressLine2,
                addressLine3: action.payload.addressLine3,
                addressType: action.payload.addressType,
                latitude: action.payload.latitude,
                longitude: action.payload.longitude,
                recordId: action.payload.recordId
            }
        default:
            return state;
    }
}