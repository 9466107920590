import React from 'react'
import { confirmAlert } from 'react-confirm-alert';

export const InformationAlert = ({ title, message }) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='text-center bg-light p-3 rounded shadow modal-dialog'>
                    {title && <h6 className="font-size-16 font-weight-800 text-dark-black my-3">{title}</h6>}
                    <div className='font-size-14 text-gray font-weight-700 mb-0'>{message}</div>
                    <button onClick={onClose} className="widget-header btn bg-yellow text-black font-weight-800 font-size-14 mx-auto px-4 d-flex justify-content-center align-items-center mt-4" style={{ height: '40px' }}>OK</button>
                </div>
            )
        }
    });
}