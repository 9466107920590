import React, { useEffect, useReducer } from 'react'
import { userReducer } from '../../reducer/reducers/userReducer';
import Cookies from 'universal-cookie';
import { registerDetails } from '../../reducer/initialStates/initialStates';
import { userLocationReducer } from '../../reducer/reducers/userLocationReducer';
import { utilityReducer } from '../../reducer/reducers/utiltiyReducer';
import { GlobalContext } from '../../reducer/context';
import { getDefaultLocation, getLatLng } from '../dashboard/common/DashboardUtils';
import { SET_LOCATION } from '../../reducer/actions/userLocationActions';
// import EditProfile from './EditProfile';
// import LoginModal from '../pages/login/LoginModal';
// import BookTableModal from './BookTableModal';
// import { SHOW_LOGIN_MODAL, TOGGLE_EDIT_PROFILE_MODAL } from '../../reducer/actions/userActions';
// import ModalBox from './ModalBox';
// import { ADD_PROFILE, DINE_IN, UPDATE_PROFILE } from '../../translations/en/en';

const cookies = new Cookies();
const initialAddress = {
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressType: "",
    latitude: 0,
    longitude: 0,
    recordId: 0,
}
const locationWaitingTime = 3000

export const AppContextWrapper = ({ children }) => {
    const [authState, dispatchAuth] = useReducer(userReducer, {
        isAuthenticated: cookies.get("authToken") !== undefined,
        authToken: cookies.get("authToken") !== undefined ? cookies.get("authToken") : null,
        user: null,
        showLoginModal: false,
        showEditProfileModal: false,
        showBookTableModal: false,
        showLoginConfirmationModal: false,
        registerDetails: { ...registerDetails },
    });
    const [userLocation, dispatchLocation] = useReducer(userLocationReducer, cookies.get("selectedUserAddress") !== undefined ? cookies.get("selectedUserAddress") : { ...initialAddress });
    const [utilityStates, dispatchUtility] = useReducer(utilityReducer, {
        userCart: localStorage.getItem("userCart")
            ? JSON.parse(localStorage.getItem("userCart"))
            : null,
        orderType: localStorage.getItem("orderType") &&
            localStorage.getItem("orderType") !== undefined
            ? JSON.parse(localStorage.getItem("orderType"))
            : null,
        restaurantList: null,
        restaurantId: null,
        restaurantName: null,
        restaurantRecordId: null,
        selectedHeaderRestaurant: { value: "", label: "", recordId: "" },
        loading: false, // TODO add recordId
    });
    useEffect(() => {
        let locationWaitingTimeOut = null
        getLatLng().then(address => {
            if (locationWaitingTimeOut) {
                clearTimeout(locationWaitingTimeOut)
            }
            dispatchLocation({ type: SET_LOCATION, payload: address });
        })
        locationWaitingTimeOut = setTimeout(() => {
            if (JSON.parse(localStorage.getItem("orderType")) == "DineIn") {
                return;
            }
            dispatchLocation({ type: SET_LOCATION, payload: getDefaultLocation() });
        }, locationWaitingTime)
    }, [])



    // useEffect(() => {
    //     let leadGenerationCalled = sessionStorage.getItem("leadGenerationCalled");
    //     if (!leadGenerationCalled && !authState.isAuthenticated) {
    //         sessionStorage.setItem("leadGenerationCalled", true);
    //         dispatchAuth({ type: SHOW_LOGIN_MODAL })
    //     }
    // }, [])

    // const toggleProfileModal = () => {
    //     dispatchAuth({ type: TOGGLE_EDIT_PROFILE_MODAL })
    // }

    return (
        <GlobalContext.Provider
            value={{
                authState,
                userLocation,
                utilityStates,
                dispatchAuth,
                dispatchLocation,
                dispatchUtility,
            }}
        >
            {children}
            {/* {authState.showEditProfileModal && <ModalBox
                headerTitle={Boolean(authState.user?.fullName) ? UPDATE_PROFILE : ADD_PROFILE}
                show={authState.showEditProfileModal}
                toggleModel={toggleProfileModal}
                shouldCloseOnOverlayClick={!Boolean(authState.user?.fullName)}
            ><EditProfile /></ModalBox>}
            {authState.showLoginModal && <LoginModal />}
            {authState.showBookTableModal && <BookTableModal />} */}
        </GlobalContext.Provider>

    )
}