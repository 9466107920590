import React, { memo, useContext, useEffect, useState } from 'react'
import { callGetApi } from '../../api/ApiCaller'
import * as ApiConst from '../../api/ApiConst'
import { useNavigate } from 'react-router-dom';
import { NO_ERROR } from "../../api/constants"
import { GlobalContext } from '../../reducer/context';
import * as clevertapEvents from '../../clevertapEvents'
import { SET_ORDER_TYPE } from '../../reducer/actions/utilityActions';
import { EVENT_LIST, HOME_DELIVERY, NO_BANNERS_ARE_AVAILABLE } from '../../translations/en/en';
import { useWindowSize, InformationAlert, LazyLoadedImage } from './index';
import { LoaderIcon } from 'react-hot-toast';


let retryCount = 0

const imageLoaderIcon = <div style={{ width: "100%", height: "200px" }} className="d-flex justify-content-center align-items-center"><LoaderIcon /></div>

export const Carousel = ({ id, bannerType }) => {
    const { userLocation, dispatchUtility } = useContext(GlobalContext)
    const [bannerList, setBannerList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { latitude, longitude } = userLocation
    const navigate = useNavigate();

    const size = useWindowSize()
    const getBanners = () => {
        setIsLoading(true)
        const rUrl = `${ApiConst.Get_AdBanner_List}/${bannerType}?lat=${latitude}&lng=${longitude}`
        callGetApi(rUrl, {}, response => {
            if (response.errorCode === NO_ERROR) {
                setBannerList(response.responsePacket)
            } else {
                InformationAlert({ message: response.message })
            }
            setIsLoading(false)
        }, error => {
            setIsLoading(false)
            if (retryCount <= 3) {
                getBanners()
            }

        })
    }

    useEffect(() => {
        if (latitude && longitude && bannerList.length === 0) {
            getBanners()
        }
    }, [bannerType, bannerList.length, latitude, longitude])

    const handleSlide = (sliderAction) => {
        const whichBanner = bannerType === "DashboardBottom" ? "BOTTOM" : "TOP"
        clevertapEvents.bannerTappedEvent(sliderAction, whichBanner)
        switch (sliderAction) {
            case HOME_DELIVERY: {
                navigate("/")
                dispatchUtility({ type: SET_ORDER_TYPE, payload: HOME_DELIVERY })
                break;
            }
            case "Reservation": {
                navigate("/restaurants")
                break;
            }
            case EVENT_LIST: {
                navigate("/")
                dispatchUtility({ type: SET_ORDER_TYPE, payload: EVENT_LIST })
                break;
            }
            default: {
                navigate("/")
                dispatchUtility({ type: SET_ORDER_TYPE, payload: HOME_DELIVERY })
                break;
            }
        }
    }

    const isWeb = size[0] >= 1080

    return (
        <div id={id} className="carousel slide home-slider mobile-h p-r" data-ride="carousel">
            {
                isLoading ? <></> : <>
                    {
                        bannerList.length <= 0 ? <div className='text-center'>{NO_BANNERS_ARE_AVAILABLE}</div> : <>
                            <ol className="carousel-indicators">
                                {
                                    bannerList.map((banner, i) => <li key={i} data-target={`#${id}`} data-slide-to={i} className={`${i === 0 && "active"}`} />)
                                }
                            </ol>
                            <div className="carousel-inner ">
                                {
                                    bannerList.map((currentBanner, i) => <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
                                        <LazyLoadedImage
                                            className="d-block w-100 cursor-pointer img-fluid lazyload"
                                            placeholder={imageLoaderIcon}
                                            src={size[0] >= 1080 ? currentBanner.webImageUrlLarge : currentBanner.adImageUrlLarge}
                                            wrapperClassName="image-holder h-auto"
                                            onClick={() => handleSlide(currentBanner.sliderAction)}
                                            width={isWeb ? "1920" : "1245"}
                                            height={isWeb ? "375px" : "810"}
                                            alt="carousal"
                                            loading="eager"
                                        />
                                        {/* <img src={size[0] >= 1080 ? currentBanner.webImageUrlLarge : currentBanner.adImageUrlLarge}
                                            className="d-block w-100 cursor-pointer"
                                            alt="banner"
                                            onClick={() => handleSlide(currentBanner.sliderAction)}
                                        /> */}
                                    </div>)
                                }
                            </div>
                        </>
                    }

                </>
            }
        </div>
    )
}

export default memo(Carousel);