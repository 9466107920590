import React, { useContext, useEffect, useReducer, useState } from "react";
import { callGetApi, callPostApi } from "../../api/ApiCaller";
import * as ApiConst from "../../api/ApiConst";
import ModalBox from "./ModalBox";
import { NO_ERROR } from "../../api/constants";
import { GlobalContext } from "../../reducer/context";
import Select from 'react-select'
import { bookTableReducer } from "../../reducer/reducers/bookTableReducer";
import { BOOK_TABLE_NOW, FIll_DETAILS, GET_OCCASION_LIST, GET_RESTAURANT_LIST } from "../../reducer/actions/bookTableActions";
import { getRestaurantKeyValuePairs } from "./customHooks";
import { bookTableInitialState } from "../../reducer/initialStates/initialStates";
import { InformationAlert } from "./InformationAlert";
import textUtils from "../../utils/TextUtils";
import { SHOW_LOGIN_MODAL, TOGGLE_BOOK_TABLE_MODAL } from "../../reducer/actions/userActions";
import { LoaderIcon } from "react-hot-toast";
import * as clevertapEvents from "../../clevertapEvents";


export const BookTableModal = () => {
    const { utilityStates, dispatchAuth, authState } = useContext(GlobalContext);
    const [{ restaurantList, occasionList, ...userDetails }, dispatchBookTable] = useReducer(bookTableReducer, { ...bookTableInitialState })
    const [errors, dispatchBookTableErrors] = useReducer(bookTableReducer, { ...bookTableInitialState })
    const [isPending, setIsPending] = useState(false);

    const { user, isAuthenticated, showBookTableModal } = authState

    useEffect(() => {
        // Get userDetails
        if (isAuthenticated && user) {
            const { fullName, mobile, email } = user
            const _payload = {
                reservedByName: fullName,
                reservedByMobile: mobile,
                reservedByEmail: email,
            }
            dispatchBookTable({ type: FIll_DETAILS, payload: _payload })
        }
        if (!isAuthenticated) {
            dispatchAuth({ type: SHOW_LOGIN_MODAL })
        }

        // OccasionList options
        if (occasionList.length === 0) {
            callGetApi(ApiConst.Table_Reservation_Occasion_List, {}, (response) => {
                if (response.errorCode === NO_ERROR) {
                    const occasionListTemp = response.responsePacket.map(occasion => ({
                        value: occasion,
                        label: occasion
                    }))
                    dispatchBookTable({ type: GET_OCCASION_LIST, payload: occasionListTemp })
                } else {
                    InformationAlert({ message: response.message })
                }
            }
            );
        }
    }, [isAuthenticated, user, occasionList.length]);

    useEffect(() => {
        // Restaurant List
        if (isAuthenticated && utilityStates.restaurantList && restaurantList.length === 0) {
            const list = getRestaurantKeyValuePairs(utilityStates.restaurantList)
            dispatchBookTable({ type: GET_RESTAURANT_LIST, payload: list })
        }
    }, [utilityStates.restaurantList, restaurantList.length, isAuthenticated]);


    const handleToggleBookTableModal = () => dispatchAuth({ type: TOGGLE_BOOK_TABLE_MODAL })

    const handleUserDetailsChange = (event) => {
        const { name, value } = event.target
        const _payload = { [name]: event.target.type === "number" ? parseInt(value) : value }
        dispatchBookTable({ type: FIll_DETAILS, payload: _payload })
        dispatchBookTableErrors({ type: FIll_DETAILS, payload: { [name]: "" } })
    };

    const handleSelectChange = (key, value) => {
        const _payload = { [key]: value }
        dispatchBookTable({ type: FIll_DETAILS, payload: _payload })
        dispatchBookTableErrors({ type: FIll_DETAILS, payload: { [key]: "" } })

    }

    const validateUserDetails = () => {
        const { isBooked, cookingInstructions, ...restDetail } = userDetails
        for (let key in restDetail) {
            if (!userDetails[key] || (key === "reservedByEmail" && !(textUtils.isEmail(userDetails[key])))) {
                dispatchBookTableErrors({ type: FIll_DETAILS, payload: { [key]: "Please fill details properly" } })
                return false;
            } else {
                dispatchBookTableErrors({ type: FIll_DETAILS, payload: { [key]: "" } })
            }
        }
        return true;
    };

    const handlePlaceBookTableOrder = (event) => {
        event.preventDefault();
        if (validateUserDetails()) {
            const rUrl = ApiConst.Save_Reservation;
            const timeStamp = new Date(userDetails.reservationDateTime).getTime();
            const {
                numberOfPeople,
                occasion,
                restaurant,
                restaurantList,
                occasionList,
                isBooked,
                ...restDetails
            } = userDetails;
            const rObj = {
                ...restDetails,
                eventId: "",
                occasion: occasion.label,
                reservationDateTime: timeStamp,
                numberOfPeople: parseInt(numberOfPeople),
                restaurantId: restaurant.recordId,
            };

            setIsPending(true)
            callPostApi(rUrl, rObj, {}, (response) => {
                if (response.errorCode === NO_ERROR) {
                    handleToggleBookTableModal()
                    InformationAlert({ title: "Success", message: response.message });
                    clevertapEvents.tableReservationEvent(
                        userDetails.restaurant.occasion,
                        userDetails.restaurant.label
                    );
                    dispatchBookTable({ type: BOOK_TABLE_NOW, payload: { ...bookTableInitialState } })
                } else {
                    InformationAlert({ title: "Oops...", message: response.message });
                }
                setIsPending(false)
            }, error => setIsPending(false));


        }
    };

    return (
        <>
            {showBookTableModal && (
                <ModalBox
                    headerTitle="Book A Table"
                    show={showBookTableModal}
                    toggleModel={handleToggleBookTableModal}
                >
                    <div className="">
                        <form>
                            <div>
                                <label
                                    className=" text-secondary font-size-12 font-weight-700"
                                    htmlFor="inputState"
                                >
                                    Restaurants
                                </label>
                                <Select className='w-100 mb-2'
                                    value={userDetails.restaurant}
                                    onChange={(event) => handleSelectChange("restaurant", event)}
                                    options={restaurantList}
                                />
                                {errors.restaurant && (<span style={{ color: "#cf000f" }}>{errors.restaurant}</span>)}
                            </div>
                            <div>
                                <label
                                    className="mb-0 text-secondary font-size-12 font-weight-700"
                                    htmlFor="inputAddress"
                                >
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control table-form mb-2 "
                                    id="inputAddress"
                                    name="reservedByName"
                                    value={userDetails.reservedByName}
                                    onChange={handleUserDetailsChange}
                                />
                                {errors.reservedByName && (<span style={{ color: "#cf000f" }}>{errors.reservedByName}</span>)}
                            </div>
                            <div>
                                <label
                                    className="mb-0 text-secondary font-size-12 font-weight-700"
                                    htmlFor="inputAddress2"
                                >
                                    Mobile Number
                                </label>
                                <input
                                    type="text"
                                    className="form-control table-form mb-2"
                                    id="inputAddress2"
                                    name="reservedByMobile"
                                    value={userDetails.reservedByMobile}
                                    onChange={handleUserDetailsChange}
                                />
                                {errors.reservedByMobile && (<span style={{ color: "#cf000f" }}>{errors.reservedByMobile}</span>)}
                            </div>
                            <div>
                                <label
                                    className="mb-0 text-secondary font-size-12 font-weight-700"
                                    htmlFor="inputAddress2"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    className="form-control table-form mb-2"
                                    id="inputAddress2"
                                    name="reservedByEmail"
                                    placeholder="dfhgdfhdgfdhjgf"
                                    value={userDetails?.reservedByEmail ? userDetails.reservedByEmail : ""}
                                    onChange={handleUserDetailsChange}
                                />
                                {errors.reservedByEmail && (<span style={{ color: "#cf000f" }}>{errors.reservedByEmail}</span>)}
                            </div>
                            <div className="form-row mb-2" style={{ position: "relative" }}>
                                <div className=" col-md-12">
                                    <label
                                        className="mb-0 text-secondary font-size-12 font-weight-700"
                                        htmlFor="reservationDateTime"
                                    >
                                        Time
                                    </label>
                                    <input
                                        type="datetime-local"
                                        className="form-control table-form mb-2"
                                        id="reservationDateTime"
                                        name="reservationDateTime"
                                        placeholder="mm/dd/yyyy --:-- --"
                                        value={userDetails.reservationDateTime}
                                        onChange={handleUserDetailsChange}
                                        min={new Date().toISOString().slice(0, 16)}
                                    />
                                    {errors.reservationDateTime && (<span style={{ color: "#cf000f" }}>{errors.reservationDateTime}</span>)}
                                </div>
                            </div>
                            <div>
                                <label
                                    className=" text-secondary font-size-12 font-weight-700"
                                    htmlFor="inputState"
                                >
                                    Occasion
                                </label>
                                <Select className='w-100 mb-3'
                                    value={userDetails.occasion}
                                    onChange={(event) => handleSelectChange("occasion", event)}
                                    options={occasionList}
                                />
                                {errors.occasion && (<span style={{ color: "#cf000f" }}>{errors.occasion}</span>)}
                            </div>
                            <div>
                                <label
                                    className="mb-0 text-secondary font-size-12 font-weight-700"
                                    htmlFor="numberOfPeople"
                                >
                                    No of People
                                </label>
                                <input
                                    type="number"
                                    className="form-control table-form mb-2"
                                    id="numberOfPeople"
                                    name="numberOfPeople"
                                    value={userDetails.numberOfPeople}
                                    onChange={handleUserDetailsChange}
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                />
                                {errors.numberOfPeople && (<span style={{ color: "#cf000f" }}>{errors.numberOfPeople}</span>)}
                            </div>
                            <div>
                                <label
                                    className="mb-0 text-secondary font-size-12 font-weight-700"
                                    htmlFor="cookingInstructions"
                                >
                                    Special Instruction
                                </label>
                                <input
                                    type="text"
                                    className="form-control table-form mb-2"
                                    id="cookingInstructions"
                                    name="cookingInstructions"
                                    value={userDetails.cookingInstructions}
                                    onChange={handleUserDetailsChange}
                                />
                                {errors.cookingInstructions && (<span style={{ color: "#cf000f" }}>{errors.cookingInstructions}</span>)}
                            </div>
                            <button
                                onClick={handlePlaceBookTableOrder}
                                className="widget-header btn w-100  bg-yellow text-black custom-btn "
                                disabled={isPending}
                            >
                                {isPending ? <span className="d-flex justify-content-center align-items-center"><LoaderIcon /></span> : "BOOK A TABLE"}
                            </button>
                        </form>
                    </div>
                </ModalBox>
            )}
        </>
    );
};

export default BookTableModal;
