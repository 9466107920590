import React from 'react'
import { NO_DETAILS_ARE_AVAILABLE } from '../../translations/en/en'

export const NoDetailsAvailable = () => {
    return (
        <>
            <div className='loading-details'>
                <img className='no-items-in-store' src="../img/no-items-in-store.png" alt={NO_DETAILS_ARE_AVAILABLE} />
            </div>
        </>
    )
}