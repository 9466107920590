// Common functions
export const setValueToStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));

export const getParsedValueFromStorage = key => JSON.parse(localStorage.getItem(key));
// export const getStringValueFromStorage = key => localStorage.getItem(key);

export const removeValueFromStorage = key => localStorage.removeItem(key);

export const clearStorage = () => localStorage.clear();

export const setDealCart = cartArr => localStorage.setItem("dealCart", JSON.stringify(cartArr));
export const getDealCart = () => JSON.parse(localStorage.getItem("dealCart"));