import React, { memo, useCallback, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { loadScript } from "./customHooks";

var addressResult = {};
export const MapAddressPicker = (props) => {
    var previousMarker = useRef(null)

    var callBack;

    const getAddressOfLatLng = useCallback((map, geoCoder, infoWindow, location) => {
        geoCoder.geocode({ location: location }, function (results, status) {
            if (status === "OK") {
                if (results[0]) {
                    if (previousMarker.current != null) {
                        previousMarker.current.setMap(null);
                    }
                    var marker = new window.google.maps.Marker({
                        position: location,
                        map: map,
                        draggable: true,
                    });

                    window.google.maps.event.addListener(marker, "dragend", function () {
                        getAddressOfLatLng(map, geoCoder, infoWindow, marker.position);
                    });

                    // infoWindow.setContent(results[0].formatted_address);
                    // infoWindow.open(map, marker);
                    // map.setZoom(14);
                    // map.setCenter({lat:location.lat(), lng:location.lng()});
                    map.panTo(location);
                    var formattedAddress = results[0].formatted_address;

                    let removedWord = formattedAddress.split(",")[0];
                    const finalAddress = formattedAddress.replace(removedWord + ",", "");

                    document.getElementById("addressText").innerHTML = finalAddress;
                    document.getElementById("lat").value = location.lat();
                    document.getElementById("lng").value = location.lng();

                    addressResult["addressText"] = finalAddress;
                    addressResult["addressLat"] = location.lat();
                    addressResult["addressLng"] = location.lng();

                    var addr = results[0].address_components;
                    var addrLength = results[0].address_components.length;
                    var ad = "";
                    for (var i = 0; i < addrLength; i++) {
                        if (addr[i].types[0] === "country") {
                            document.getElementById("mapCountry").value = addr[i].long_name;
                            addressResult["addressCountry"] = addr[i].long_name;
                        } else if (addr[i].types[0] === "administrative_area_level_1") {
                            document.getElementById("mapState").value = addr[i].long_name;
                            addressResult["addressState"] = addr[i].long_name;
                        } else if (addr[i].types[0] === "administrative_area_level_3" || addr[i].types[0] === "administrative_area_level_2") {
                            document.getElementById("mapCity").value = addr[i].long_name;
                            addressResult["addressCity"] = addr[i].long_name;
                        } else if (addr[i].types[0] === "postal_code") {
                            document.getElementById("mapPostalCode").value =
                                addr[i].long_name;
                            addressResult["addressPostalCode"] = addr[i].long_name;
                        } else {
                            ad = ad + ", " + addr[i].long_name;
                        }
                    }
                    document.getElementById("mapAddress").value = ad.slice(2);
                    previousMarker.current = marker;
                } else {
                    toast("No results found");
                }
            } else {
                // location.reload();
            }
        });
    }, []);

    const addMarkerOnMap = useCallback((map, location) => {
        // var infoWindow = props.currentLocation.addressLat ? new window.google.maps.InfoWindow({
        //     content:
        //         "Latitude: " + props.currentLocation.addressLat + "<br>Longitude: " + props.currentLocation.addressLng,
        // }) :
        //     new window.google.maps.InfoWindow({
        //         content:
        //             "Latitude: " + location.lat() + "<br>Longitude: " + location.lng(),
        //     })
        var infoWindow = new window.google.maps.InfoWindow({
            content:
                "Latitude: " + location.lat() + "<br>Longitude: " + location.lng(),
        });
        var geoCoder = new window.google.maps.Geocoder();
        getAddressOfLatLng(map, geoCoder, infoWindow, location);
    }, [getAddressOfLatLng]);

    const initAutocomplete = useCallback((map) => {
        // Create the search box and link it to the UI element.
        var input = document.getElementById("pac-input");
        // var input = searchRef.current;
        var searchBox = new window.google.maps.places.SearchBox(input);
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

        // Bias the SearchBox results towards current map's viewport.
        map.addListener("bounds_changed", function () {
            searchBox.setBounds(map.getBounds());
        });

        //var markers = [];
        searchBox.addListener("places_changed", function () {
            var places = searchBox.getPlaces();
            if (places.length === 0) {
                return;
            }
            // For each place, get the icon, name and location.
            new window.google.maps.LatLngBounds();
            places.forEach(function (place) {
                if (!place.geometry) {
                    return;
                }
                addMarkerOnMap(map, place.geometry.location);
            });
        });
    }, [addMarkerOnMap]);

    const onMapReady = useCallback(() => {
        const curLocTemp = props.currentLocation
        var myLocation = null
        if (curLocTemp.addressLat) {
            myLocation = new window.google.maps.LatLng(curLocTemp.addressLat, curLocTemp.addressLng)
        } else if (curLocTemp.latitude) {
            myLocation = new window.google.maps.LatLng(curLocTemp.latitude, curLocTemp.longitude)
        } else {
            myLocation = new window.google.maps.LatLng(0, 0);
        }

        // var myLocation = new window.google.maps.LatLng(0, 0);
        var mapOptions = {
            center: myLocation,
            zoom: 18,
            mapTypeId: window.google.maps.MapTypeId.TERRAIN,
            disableDefaultUI: true,
            // zoomControl: true,
            // scaleControl: false,
        };
        var map = new window.google.maps.Map(
            document.getElementById("map"),
            mapOptions
        );
        window.google.maps.event.addListener(map, "click", function (event) {
            addMarkerOnMap(map, event.latLng);
        });

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    var location = null
                    if (curLocTemp.addressLat) {
                        location = new window.google.maps.LatLng(curLocTemp.addressLat, curLocTemp.addressLng)
                    } else if (curLocTemp.latitude) {
                        location = new window.google.maps.LatLng(curLocTemp.latitude, curLocTemp.longitude)
                    } else {
                        location = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
                    }
                    addMarkerOnMap(map, location);
                    map.setCenter(location);
                },
                function () {
                    // handleLocationError(true, infoWindow, map.getCenter());
                }
            );
        } else {
            // Browser doesn't support Geolocation
            // handleLocationError(false, infoWindow, map.getCenter());
        }
        initAutocomplete(map);
    }, [addMarkerOnMap, initAutocomplete, props.currentLocation, props.currentLocation]);

    useEffect(() => {
        const mapUrl = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.REACT_APP_GOOGL_MAP_MERCHANT_KEY}`
        if (!window.google && !window?.google?.maps) {
            loadScript(mapUrl).then(isLoaded => {
                if (isLoaded) {
                    onMapReady();
                } else {
                    toast("Unable to load Map")
                }
            })

        } else {
            onMapReady();
        }
    }, [onMapReady]);


    const submit = () => {
        var lat = document.getElementById("lat").value;
        var lng = document.getElementById("lng").value;
        if (lat === 0 && lng === 0) {
            toast("Please select location first.");
            return;
        }
        else {
            props.locationCallBack(addressResult)
        }

        // hideGoogleMapDialog();
        if (callBack != null) {
            callBack(addressResult);
        }
    };

    return (
        <>
            <div
                className="googleMapDialog"
                style={{ background: "rgba(0, 0, 0, 0.6); z-index: 9999" }}
            >
                <div>
                    <input
                        id="pac-input"
                        className="controls form-control"
                        type="text"
                        placeholder="Search location by address here"
                    />
                    <div id="map" className="w-100" style={{ height: "300px" }}></div>
                    <input type="hidden" id="lat" value={props.currentLocation.addressLat} />
                    <input type="hidden" id="lng" value={props.currentLocation.addressLng} />
                    <input type="hidden" id="mapAddress" value="" />
                    <input type="hidden" id="mapCountry" value={props.currentLocation.addressCountry} />
                    <input type="hidden" id="mapState" value={props.currentLocation.addressState} />
                    <input type="hidden" id="mapCity" value={props.currentLocation.addressCity} />
                    <input type="hidden" id="mapPostalCode" value={props.currentLocation.addressPostalCode} />
                </div>
                <label className="form-control addressLabel" id="addressText">
                    Click on map to get address
                </label>
                <div className="text-end mt-2">
                    <span
                        className="widget-header btn bg-yellow text-black font-weight-900 font-size-16 d-flex justify-content-center align-items-center py-3"
                        onClick={submit}
                    >
                        Submit
                    </span>
                </div>
            </div>
        </>
    );
};

export default memo(MapAddressPicker);


/**
 * Installation Guide
 *      -- "@react-google-maps/api": "^2.17.0"
 *      -- npm i @react-google-maps/api
 * 
 * How To USE Guide
 * 
    const locationCallBack = (loc) => {
        console.log("loc: ", loc);
        console.log(" addressText : ", loc.addressText);
        console.log(" addressLat : ", loc.addressLat);
        console.log(" addressLng : ", loc.addressLng);
        console.log(" addressCountry : ", loc.addressCountry);
        console.log(" addressState : ", loc.addressState);
        console.log(" addressCity : ", loc.addressCity);
        console.log(" addressPostalCode : ", loc.addressPostalCode);
    }
        const [currentLocation, setCurrentLocation] = useState({
        addressCountry: '',
        addressLat: 0.0,
        addressLng: 0.0,
        addressPostalCode: '',
        addressState: '',
        addressCity: '',
        addressText: ''
    })
    const [showAddressPicker, setShowAddressPicker] = useState(false);
    const drawLocationPickerModel = () => {
        return (
            <>
                <Modal size="xl" show={showAddressPicker} onHide={() => setShowAddressPicker(false)} id="locationPicker" style={{background:"rgba(0, 0, 0, .5)"}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Location</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MapAddressPicker currentLocation={currentLocation} locationCallBack={locationCallBack} />
                    </Modal.Body>
                </Modal>
            </>
        )
    }

    // Add script
        <script src="https://maps.googleapis.com/maps/api/js?libraries=places&amp;key=<MAP_KEY>"></script>

    // Styling 
    1. If input's autocomplete is not working then use this CSS

            .pac-container {
                z-index: 1100 !important;
            }
 * 
 */