import Cookies from "universal-cookie";
import dateUtilities from "../../utils/DateUtilities";
import {
    LOGIN,
    LOGOUT,
    SHOW_LOGIN_MODAL,
    HIDE_LOGIN_MODAL,
    EDIT_PROFILE,
    FILL_REGISTER_DETAILS,
    TOGGLE_EDIT_PROFILE_MODAL,
    TOGGLE_BOOK_TABLE_MODAL,
	OPEN_EDIT_PROFILE_MODAL,
} from "../actions/userActions";
import * as clevertapEvents from "../../clevertapEvents";
import {requestNotificationPermission} from "../../firebase/firebaseUtilities";
import * as AirBridgeUtilities from "../../components/airbridge/AirBridgeUtilities";

const cookies = new Cookies();

export const userReducer = (state, action) => {
    switch (action.type) {
        case LOGIN:
            const hash = btoa(
                action.payload.mobile + ":" + action.payload.secretKey
            );
            const authToken = "Basic " + hash;
            var expireDate = new Date();
            expireDate.setDate(expireDate.getDate() + 30);
            cookies.set(
                "authToken",
                {accessToken: authToken},
                {path: "/", expires: expireDate}
            );
            const userData = {
                ...action.payload,
                dateOfBirth:
                    action.payload.dateOfBirth &&
                    action.payload.dateOfBirth !== undefined
                        ? dateUtilities.formattedDate(
                              action.payload?.dateOfBirth,
                              "yyyy-MM-dd"
                          )
                        : "",
            };
            requestNotificationPermission();
            clevertapEvents.profileEvent(action.payload);
            clevertapEvents.verifyOtpEvent(action.payload.mobile);
            clevertapEvents.loginEvent(action.payload.mobile);
            AirBridgeUtilities.userSignInEvent(action.payload);
            AirBridgeUtilities.loginEvent(action.payload);
            // cookies.set("user", action.payload, { path: '/', expires: expireDate });
            return {
                ...state,
                isAuthenticated: true,
                user: userData,
                showLoginModal: false,
            };
        case LOGOUT:
            cookies.remove("authToken", {path: "/"});
            localStorage.clear();
            // cookies.remove("user",{path:"/"});
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        case EDIT_PROFILE:
            cookies.set("user", action.payload, {
                path: "/",
                expires: expireDate,
            });
            return {
                ...state,
                user: action.payload,
            };
        case SHOW_LOGIN_MODAL:
            return {
                ...state,
                showLoginModal: true,
            };
        case HIDE_LOGIN_MODAL:
            return {
                ...state,
                showLoginModal: false,
            };
        case FILL_REGISTER_DETAILS:
            return {
                ...state,
                registerDetails: {...state.registerDetails, ...action.payload},
            };
        case TOGGLE_EDIT_PROFILE_MODAL:
            return {
                ...state,
                showEditProfileModal: !state.showEditProfileModal,
            };
        case OPEN_EDIT_PROFILE_MODAL:
            return {
                ...state,
                showEditProfileModal: true,
            };
        case TOGGLE_BOOK_TABLE_MODAL:
            return {
                ...state,
                showBookTableModal: !state.showBookTableModal,
            };
        default:
            return state;
    }
};
