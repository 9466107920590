
import ClevertapReact from "clevertap-react";
import { EVENT_LIST } from "./translations/en/en";

export const profileEvent = (profileObj) => {
    var clevertapObj = {
        Site: {
            Name: profileObj.fullName,
            Identity: profileObj.uuid,
            LoginFrom:
                profileObj.facebookData != null
                    ? "Facebook"
                    : "Web",
            user_email: profileObj.email,
            Email: profileObj.email,
            user_mobile: "+91" + profileObj.mobile,
            Phone: "+91" + profileObj.mobile,
            Gender: profileObj.gender === "MALE" ? "M" : "F",
            Tz: "Asia/Kolkata",
            Photo: profileObj.userImage,
        },
    };
    if (
        profileObj.facebookData &&
        profileObj.facebookData.facebookUserId
    ) {
        clevertapObj.Site.Facebook_Id =
            profileObj.facebookData.facebookUserId;
    }
    if (profileObj.anniversaryDate) {
        clevertapObj.Anniversary = new Date(profileObj.anniversaryDate);
    }
    if (profileObj.dateOfBirth) {
        clevertapObj.Site.DOB = new Date(profileObj.dateOfBirth);
    }
    ClevertapReact.profile(clevertapObj);
};

export const verifyOtpEvent = (mobileNo) => {
    const clevertapObj = {
        "Mobile": mobileNo
    }
    ClevertapReact.event(`OTP Verified`, clevertapObj);
};

export const loginEvent = (mobileNo) => {
    const clevertapObj = {
        "Mobile": mobileNo
    }
    ClevertapReact.event(`Login`, clevertapObj);
};

export const categoryViewedEvent = (obj, orderType, mainOrSubCat) => {
    if(mainOrSubCat === "mainCat"){
        ClevertapReact.event(`${orderType} Category Viewed`, obj);
    } else if (mainOrSubCat === "subCat") {
        ClevertapReact.event(`${orderType} Sub Category Viewed`, obj);
    }
};

export const itemAddToCartEvent = (obj, orderType) => {
    const clevertapObj = {
        "Item Id": obj.itemId,
        "Item Name": obj.title,
        "SubCategory Name": obj.subCategoryTitle,
        "Category Name": obj.categoryTitle,
        "Location Name": obj.restaurantName,
        "Price": obj.finalPrice
    }
    ClevertapReact.event(`${orderType} Item Added To Cart`, clevertapObj);
};

export const searchEvent = (keyword, orderType) => {
    const clevertapObj = {
        "keyword": keyword
    }
    ClevertapReact.event(`${orderType} Searched`, clevertapObj);
};

export const StealDealItemViewedEvent = (obj, restaurantName) => {
    const clevertapObj = {
        "Item Id": obj.recordId,
        "Item Name": obj.title,
        "Category Name": obj.categoryTitle,
        "Location Name": restaurantName,
        "Price": obj.price
    }
    ClevertapReact.event(`StealDeal Item Viewed`, clevertapObj);
};

export const eventReservedEvent = (occasionTitle) => {
    const clevertapObj = {
        "Event Name": occasionTitle
    }
    ClevertapReact.event(`Event Reserved`, clevertapObj);
};

export const tableReservationEvent = (occasionTitle, restaurantName) => {
    const clevertapObj = {
        "Occasion": occasionTitle,
        "Location Name": restaurantName
    }
    ClevertapReact.event(`Table Reservation`, clevertapObj);
};

export const payAtStoreEvent = (amount) => {
    const clevertapObj = {
        "Amount": amount
    }
    ClevertapReact.event(`Pay at Store`, clevertapObj);
};

export const rateOrderEvent = (ratingObj, orderDetails) => {
    const clevertapObj = {
        "Driver Rating":  ratingObj.driverRating > 0 ? ratingObj.driverRating : "N/A",
        "Restaurant Rating": ratingObj.restaurantRating > 0 ? ratingObj.restaurantRating : "N/A",
        "Location": orderDetails.restaurantName,
        "Order Type": orderDetails.orderType ? orderDetails.orderType : "N/A",
        "Order Id": orderDetails.orderRefId,
    }
    ClevertapReact.event(`Rate`, clevertapObj);
};

export const rateItemEvent = (ratingObjList, restaurantName) => {
    const clevertapObj = {
        "Location": restaurantName,
        "Item Id": ratingObjList.orderItemId,
        "Item Name": ratingObjList.title,
        "Item Rating": ratingObjList.itemRating > 0 ? ratingObjList.itemRating : "N/A",
    }
    ClevertapReact.event(`Rate Item`, clevertapObj);
};

export const rewardScratchedEvent = (clevertapObj) => {
    ClevertapReact.event(`Rewards Scratched`, clevertapObj);
};

export const stealDealConsumeEvent = (obj) => {
    const clevertapObj = {
        "Item Id": obj.stealDealItemId,
        "Item Name": obj.stealDealItemTitle,
        "Location Name": obj.restaurantName,
        "Remaining Quantity": obj.quantity,
    }
    ClevertapReact.event(`StealDeal Consume Item`, clevertapObj);
};

export const voucherConsumeEvent = (obj) => {
    const clevertapObj = {
        "Voucher Code Used": obj,
    }
    ClevertapReact.event(`Voucher Consumed`, clevertapObj);
};

export const applyGiftCodeEvent = (obj) => {
    const clevertapObj = {
        "StealDeal Gift Code": obj,
    }
    ClevertapReact.event(`StealDeal Apply Gift Code`, clevertapObj);
};

export const generateGiftCodeEvent = (obj) => {
    const clevertapObj = {
        "StealDeal Gift Code": obj,
    }
    ClevertapReact.event(`StealDeal Generate Gift Code`, clevertapObj);
};

export const bannerTappedEvent = (obj, whichBanner) => {
    const clevertapObj = {
        "Banner Navigated To": obj,
    }
    if(whichBanner.toUpperCase() === "TOP"){
        ClevertapReact.event(`Top Banner Clicked`, clevertapObj);
    } else if(whichBanner.toUpperCase() === "BOTTOM"){
        ClevertapReact.event(`Bottom Banner Clicked`, clevertapObj);
    }
};

export const eventViewedEvent = (obj, whichEvent) => {
    const clevertapObj = {
        "Event Name": obj.occasionTitle,
        "Address": obj.occasionAddress,
    }
    if(whichEvent.toUpperCase() === "UPCOMING"){
        ClevertapReact.event(`Event Viewed`, clevertapObj);
    } else if(whichEvent.toUpperCase() === "PAST"){
        ClevertapReact.event(`Past Event Viewed`, clevertapObj);
    }
};

export const sideMenuClickEvent = (menuName) => {
    ClevertapReact.event(`Side Menu ${menuName}`);
};

export const orderTypeTabsClickEvent = (tabName) => {
    ClevertapReact.event(`Dashboard ${tabName} clicked`);
};

export const walletAction = (amount) => {
    const clevertapObj = {
        "Amount": amount
    }
    ClevertapReact.event(`Add Money in Wallet`, clevertapObj);
};

export const paymentFailedAction = (paymentMode, reason) => {
    const clevertapObj = {
        "Payment Mode": paymentMode,
        "Reason": reason,
    }
    ClevertapReact.event(`Wallet Payment Failed`, clevertapObj);
};

export const locationEvent = (locObj) => {
    // TODO
    // ClevertapReact.event(`Set Location`, locObj);
};

export const pushChargedEvent = (orderDetails, paymentMode) => {
    const clevertapObj = {
        "Payment Mode": paymentMode ? paymentMode : "Wallet",
        "Location Name": orderDetails.restaurantName,
        "Order Type": orderDetails.orderType,
        "Delivery Offer Applied": orderDetails.isDeliveryOfferApplicable ? "Applied" : "N/A",
        "Coupon Code Applied": orderDetails.couponCode ? orderDetails.couponCode : "N/A",
        "Coupon Code Amount": orderDetails.couponDiscountAmount,
        "Amount": orderDetails.orderTotal,
        "Items": []
    }
    orderDetails.itemList.map(item=>clevertapObj["Items"].push({
        "Item Name": item.title,
        "Item Id": item.itemId,
        "Quantity": item.quantity,
        "Type": item.vegNonVeg ? item.vegNonVeg : "non",
    }))
    // ClevertapReact.event("Charged", clevertapObj);
};

export const pushChargedEventForEvent = (orderDetails, paymentMode) => {
    const clevertapObj = {
        "Payment Mode": paymentMode ? paymentMode : "Wallet",
        "Location Name": orderDetails.restaurantName,
        "Order Type": orderDetails.orderType,
        "Delivery Offer Applied": orderDetails.isDeliveryOfferApplicable ? "Applied" : "N/A",
        "Coupon Code Applied": orderDetails.couponCode ? orderDetails.couponCode : "N/A",
        "Coupon Code Amount": orderDetails.couponDiscountAmount,
        "Amount": orderDetails.paidOnDelivery,
        "Items": []
    }
    if(orderDetails.orderType === EVENT_LIST){
        orderDetails.itemList.map(item=>clevertapObj["Items"].push({
            "Ticket Name": item.ticketName,
            "Ticket Id": item.ticketId,
            "Quantity": item.ticketCount
        }))
    } else {
        orderDetails.itemList.map(item=>clevertapObj["Items"].push({
            "Item Name": item.title,
            "Item Id": item.itemId,
            "Quantity": item.quantity,
            "Type": item.vegNonVeg ? item.vegNonVeg : "non",
        }))
    }
    // ClevertapReact.event("Charged", clevertapObj);

};

export const webAppLaunchedEvent=()=>{
    ClevertapReact.event(`WebApp Launched`,"WebApp Launched");
}
export const errorLogs=(errorObj)=>{
    ClevertapReact.event(`Error Logs`, errorObj);
}

export const restErrorLogs = (restId, log) => {
    const cleverObj = {
        Rest_Id: restId,
        message: log 
    }
    ClevertapReact.event(`Rest Error Logs`, cleverObj);
}