// order type
export const ALL = "All";
export const HOME_DELIVERY = "HomeDelivery";
export const DINE_IN = "DineIn";
export const TAKE_AWAY = "TakeAway";
export const STEAL_DEAL = "StealDeal";
export const EVENT_LIST = "EventList";

// Header
export const LOGIN = "Login";
export const UPPERCASE_LOGIN = "lOGIN";
export const LOCATIONS = "Locations";
export const BOOK_A_TABLE = "Book a Table";
export const TAP_TO_PICK_LOCATION = "Tap on it to pick your location";
export const PICK_LOCATION = "Pick your location";
export const UNAUTHORIZED_ACCESS = "Unauthorized Access";
export const YOU_ARE_NOT_AUTHORIZED =
  "You are not authorized to access this feature. Please login to proceed further.";
export const NO_BANNERS_ARE_AVAILABLE = "No banners are available";
// Login
export const WELCOME_TO_PYRAMID = "Welcome To Pyramid!";
export const LOGIN_CREATE = "Login/Create";
export const BACT_TO_DASHBOARD = "Back to Dashboard";
export const CREATE_ACCOUNT = "Account to manage \n orders and services";
export const PLEASE_ENTER_YOUR_MOBILE_NUMBER =
  "Enter your mobile number to proceed";
export const AN_OTP_HAVE_SENT_TO = "An OTP has been sent to";
export const VERIFY_OTP = "VERIFY OTP";

// Footer
export const ABOUT_US = "About Us";
export const GET_FRANCHISE = "Get Franchisee";
export const CEO_SPEAKS = "CEO Speaks";
export const ONLINE_SUPPORT = "Chat With Us";
export const CONTACT_US = "Contact Us";
export const JOB_CAREER = "Apply For Job";
export const NUTRITION_INFORMATION = "Nutrition Information";
export const PRIVACY_POLICY = "Privacy Policy";
export const TERMS_CONDITIONS = "Terms & Conditions";
export const TERMS_AND_CONDITIONS = "Terms And Conditions";
export const DOWNLOAD_OUR_APP = "Download Our App";
export const APP_STORE = "App store";
export const PLAY_STORE = "Play store";
export const FOLLOW_US_ON = "Follow Us On";
export const COPYRIGHT_RESERVED = "© 2023 Company All rights reserved";
export const APP_VERSION = "Version 0.0.";
export const APP_VERSION_NUMBER = 78;

export const ADDRESS = "My Address";
export const HOME = "Home";
export const ACCOUNT = "Account";
export const MY_ORDERS = "My Orders";
export const SETTINGS = "Settings";

export const OFFER_AND_REWARDS = "Offers & Rewards";
export const NO_SCRATCH_CARDS_AVAILABLE =
  "No offers/Scratch cards are available";
export const COPY = "Copy";

export const NOTIFICATION_SETTINGS = "Notification Setting";
export const ALL_ = "All";
export const NO_DATA_AVAILABLE = "No Data Available";
export const NO_DETAILS_AVAILABLE = "No Details are available";
export const NO_RESTAURANTS_AVAILABLE = "No restaurants are available";
export const SAVE = "Save";
export const NOTIFICATION = "Notifications";
export const NO_RESERVATIONS = "No reservation notifications are available";

export const FEEDBACK = "Feedback";
export const SELECT_RESTAURANT = "Select Restaurant";
export const QUALITY_OF_FOOD = "Quality of Food";
export const SERVICES = "Services";
export const CLEANLINESS = "Cleanliness of dining premises";
export const BEHAVIOR_OF_STAFF = "Behavior of Staff";
export const WHAT_WOULD_MAKE_YOU_RETURN =
  "What would make you return frequent to us?";
export const SUGGESTION = "Suggestion";
export const CLEAR = "Clear";
export const SUBMIT = "Submit";
export const MUSIC = "Music";
export const TYPE = "Type";
export const TXN_AMOUNT = "Txn Amount";
export const AWAY = "away";
export const SORRY_THIS_RESTAURANT_IS_NOT_DELIVERING_IN_YOUR_AREA =
  "Sorry! this restaurant is not delivering in your area";

// checkout
export const CHECKOUT = "Checkout";
export const APPLY = "Apply";
export const APPLY_PROMO_CODE = "Apply Promo Code";
export const NO_COUPONS_AVAILABLE = "No Coupons available";
export const ADD = "Add";
export const BACK = "Back";
export const PREVIOUS_ITEMS = "Previous Items";
export const NEW_ITEMS = "New Items";
export const UPDATE_ORDER = "Update Order";
export const DELIVERY_ADDRESS = "Delivery address";
export const CHANGE_ADDRESS = "Change address";
export const REVIEW_ORDER = "Review Order";
export const DO_NOT_SEND_CUTLERY = "Don't Send Cutlery";
export const WE_WILL_INFORM_NOT_TO_SEND_CUTLERY =
  "We will inform the restaurant to save the plastic. Thank You.";
export const BILLING_INFORMATION = "Billing Information";
export const RESTAURANT_BILL = "Restaurant Bill";
export const TAX_CHARGES = "Taxes & Charges";
export const TAX_DISCOUNT = "Taxes Discount";
export const DELIVERY_FEE = "Delivery Fee";
export const COUPON_DISCOUNT = "Coupon Discount";
export const TOTAL = "Total";
export const PAY_BY_LOYALTY_POINTS = "Pay by Loyalty Points";
export const AVAILABLE_LOYALTY_POINTS = "Available Loyalty Points";
export const PAYMENT_MODE = "Payment Mode";
export const PAY_BY_WALLET = "Pay by Wallet";
export const AVAILABLE_WALLET_AMOUNT = "Available wallet amount";
export const CHANGE = "Change";
export const ADD_PAYMENT_MODE = "Add Payment Mode";
export const PAY = "Pay";
export const COOKING_INSTRUCTIONS = "Cooking Instructions";
export const CHANGE_PAYMENTS_METHODS = "Change Payment Methods";
export const ARE_YOU_SURE_YOU_WANT_TO_DELIVER_HERE =
  "Are you sure you want to deliver your order at";
export const SELECT_DELIVERY_ADDRESS = "Select Delivery Address";
export const ORDER_PLACED = "Order Placed";
export const TRANSACTION_FAILED = "Transaction Failed";

export const WALLET_MONEY = "Wallet Money";
export const YOUR_BALANCE = "Your Balance";
export const LOYALTY_POINTS = "Loyalty Points";
export const SPEND = "Spend";
export const HISTORY = "History";
export const YOUR_CURRENT_BALANCE = "Your current wallet balance";
export const TOTAL_AMOUNT_TO_BE_DEDUCTED = "Total ₹ to be deducted";
export const ENTER_AMOUNT_TO_BE_PAID = "Enter amount to be paid";
export const PAY_AT_STORE = "Pay At Store";
export const PAY_YOUR_BILL_USING = "Pay Your bill using ₹";
export const ADD_TO_YOUR_WALLET = "Add ₹ To Your Wallet";
export const ADD_SECURELY = "Add Securely";
export const HAVE_A_PROMO_CODE = "Have a Promo Code";
export const APPLY_CODE = "APPLY CODE";

export const RATING = "Rating";
export const ORDER_DETAILS = "Order Details";
export const DRIVER_DETAILS = "Driver Details";
export const BILL_DETAILS = "Bill Details";
export const GRAND_TOTAL = "Grand Total";
export const VALID_TILL = "Valid till";
export const DETECT_LOCATION = "Detect location";
export const YOUR_INFORMATION = "Your Information";
export const FIRST_NAME = "First Name";
export const LAST_NAME = "Last Name";
export const EMAIL_ADDRESS = "Email Address";
export const MOBILE_NUMBER = "Mobile Number";
export const EDUCATION = "Education";
export const GENDER = "Gender";
export const MALE = "Male";
export const FEMALE = "Female";
export const GET_IN_TOUCH = "Get in Touch";
export const ORDER_STATUS = "Order Status";

export const QUANTITY_ADD = "ADD";
export const QUANTITY_LESS = "LESS";
export const CASH_ON_IS_NOT_AVAILABLE_EVENTS =
  "Cash On Is Not Available For Events";
export const PLEASE_CHOOSE_DIFFERENT_PAYMENT_METHOD =
  "Please Choose Different Payment Method";
export const PLEASE_CHOOSE_PAY_METHOD_AGAIN =
  "Please Choose Payment Method again";
// Steal Deal
export const CATEGORIES = "Categories";
export const BOOK_NOW = "Book Now";
export const NO_CATEGORIES_ARE_AVAILABLE = "No categories are available";
export const CONSUME = "Consume";
export const NO_ITEMS_ARE_AVAILABLE = "No Items are available";
export const APPLY_GIFT_CODE = "Apply Gift Code";
export const DEAL_DETAILS = "Deal Details";
export const CART = "Cart";
export const YOUR_CART_IS_EMPTY = "No Items in Cart";
export const EMPTY_CART = "Empty Cart";
export const NO_DETAILS_ARE_AVAILABLE = "No details are available";
export const CLEAR_CART_CONFIRMATION = "Clear Cart Confirmation";
export const ALREADY_HAVE_ITEMS_IN_CART_WITH_DIFFERENT_RESTAURANT =
  "You already have items in your cart with different restaurant. Do you want to proceed via clearing cart?";
export const ALREADY_HAVE_ITEMS_IN_CART_WITH_DIFFERENT_ORDERTYPE =
  "You already have items in your cart with different Order Type. Do you want to proceed via clearing cart?";
export const ADD_TO_CART = "ADD TO CART";
export const VIEW_CART = "View Cart";
export const NO_TAX_DETAILS_ARE_AVAILABLE = "No tax details are available";
export const OK = "OK";
export const LOW_BALANCE = "Low Balance";
export const YOUR_WALLET_BALANCE_IS_LOW_PLEASE_ADD_MONEY =
  "Your wallet balance is low.\n Please add money in your wallet.";
export const I_ACCEPT_TERMS_AND_CONDITIONS =
  "I accept terms and Conditions as per App and Website";
export const RESERVE_NOW = "RESERVE NOW";
export const REMAINING = "Remaining";
export const GENERATE_GIFT_CODE = "Generated Gift Code";
export const PLEASE_ASK_YOUR_FRIEND_TO_DOWNLOAD_THE_APP =
  "Please ask your friend to download the app";
export const TO_REDEEM_THE_GIFT_CODE = "to redeem the gift";
export const SHARE = "Share";
export const QTY_REMAINING = "Qty Remaining";
export const USER_SLIDER_BELOW_TO_CHOOSE_COUNT_TO_CONSUME_OR_GIFT =
  "User slider below to choose count to consume or gift";
export const ITEM_SUMMARY = "Item Summary";
export const ITEM_NAME = "Item Name";
export const QUANTITY = "Quantity";
export const SCAN_AND_CONTINUE = "Scan And Continue";
export const OR = "Or";
export const GIFT_TO_SOMEONE = "GIFT TO SOMEONE";
export const STORE = "Store";
export const CONFIRM = "Confirm";
export const PLEASE = "Please";

// conditional constants
export const _BOOKNOW = "BookNow";
export const _CONSUME = "Consume";
export const _CLEAR = "CLEAR";
export const _UPCOMING = "upComing";
export const _PAST = "PAST";

// Dashboard
export const DELIVERY_ = "Delivery";
export const TAKE_AWAY_ = "TakeAway";
export const DINE_IN_ = "Dine In";
export const STEAL_DEALS_ = "Steal Deals";
export const EVENTS_ = "Events";

// TOASTS
export const OOPS_NOT_ITEMS_FOUND = "Oops! No Items Found...";
export const PLEASE_SELECT_SOME = "Please select some";
export const PLEASE_SELECT_TERMS_AND_CONDITIONS =
  "Please select terms and conditions";
export const NO_ITEM_FOUND_IN_UPCOMING_EVENTS =
  "No Items Found in Upcoming Events";
export const NO_ITEM_FOUND_IN_PAST_EVENTS = "No Items Found in Past Events";
export const OOPS = "Oops!";
export const SUCCESS = "Success";
export const PLEASE_ENTER_DETAILS_PROPERLY = "Please enter details properly";
export const FETCHING_EVENT_TICKETS = "Fetching event tickets...";
export const NO_MORE_TICKETS_ARE_AVAILABLE = "No more tickets are available.";
export const PROFILE_PICTURE_SIZE_SHOULD_BE_LESS_THAN_1_MB = "Profile picture size should be less than 1 MB";
export const PLEASE_ENTER_VALID_MOBILE_NUMBER =
  "*Please enter a valid mobile number";
export const PLEASE_WAIT = "Please wait...";
export const OTP_SENT = "OTP sent";
export const PLEASE_ENTER_OTP_PROPERLY = "*Please enter OTP properly";
export const VERIFYING_OTP = "Verifying OTP...";
export const PACKING_CHARGES = "Packing Charges";
export const PLEASE_CHECK_INTERNET_CONNECTION =
  "Unstable network or please check your internet connection";
export const SOMETHING_WENT_WRONG = "Something went wrong";

// Events
export const UPCOMING = "Upcoming";
export const PAST = "Past";
export const EVENT_DETAILS = "Events Details";
export const START_TIME = "Start Time";
export const START = "Start";
export const END = "End";
export const MAP = "Map";
export const CALL = "Call";
export const NAME = "Name";
export const MOBILE = "Mobile";
export const TIME = "Time";
export const NO_OF_PEOPLE = "No. of People";
export const PAY_SECURELY = "PAY SECURELY";
export const SOLD = "SOLD";
export const VENUE = "Venue";
export const DETAILS = "Details";
// Profile Side Menu
export const DELETE_ACCOUNT = "Delete Account";
export const CONFIRM_LOGOUT = "Confirm Logout";
export const ARE_YOU_SURE_YOU_WANT_LOGOUT = "Are you sure you want to logout?";
export const ARE_YOU_SURE_YOU_WANT_DELETE =
  "Are you sure you want to delete this account?";
export const YES = "Yes";
export const CANCEL = "Cancel";
export const LOGOUT = "Logout";
export const DELETE = "Delete";
export const LOGGED_OUT_SUCCESSFULLY = "Logged out Successfully";
export const ACCOUNT_DELETED_SUCCESSFULLY = "Account deleted Successfully";
export const CONFIRM_DELETION = "Confirm Deletion";
export const EDIT = "Edit";

// Edit Profile
export const DATE_OF_BIRTH = "Date of Birth";
export const ANNIVERSARY_DATE = "Anniversary Date";
export const PROFILE_UPDATED_SUCCESSFULLY = "Profile updated successfully";
export const OTHER = "other";
export const UPDATE_PROFILE = "UPDATE PROFILE";
export const ADD_PROFILE = "Add PROFILE";

// EDIT PROFILE TOAST
export const PLEASE_SELECT_PROFILE_IMAGE = "*Please select profile image";
export const PLEASE_ENTER_FULL_NAME = "*Please enter full name";
export const PLEASE_ENTER_VALID_EMAIL = "*Please enter a valid email";
export const PLEASE_SELECT_DOB = "*Please select DOB";
export const PLEASE_SELECT_ANNIVERSARY = "*Please select anniversary date";
export const PLEASE_SELECT_GENDER = "*Please select gender";

// Header
export const NO_RESTAURANT_FOUND_ON_SELECTED_RESTAURANT =
  "Oops! No Restaurants Found on the selected address";
export const NO_RESTAURANT_AVAILABLE = "No restaurants Available";

// Cart
export const INSTRUCTION_ADDED_SUCCESSFULLY = "Instruction Added successfully";

// FoodItem
export const CUSTOMIZABLE = "Customizable";
export const ITEM_ADDED_TO_CART = "Item added to cart";
export const VISIT_CART = "Visit Cart";
export const THIS_ITEM_HAS_MULTIPLE_CUSTOMIZATION =
  "This item has multiple customizations. Please proceed to cart to modify the desired item.";
export const INGREDIENTS = "Ingredients";
export const NOW = "Now";

// OrderView
export const THERE_ARE_NO_ITEMS_TO_DISPLAY = "There are no items to display";
export const ACCEPTING_THIS_WILL_CLEAR_YOUR_CART_STILL_CONTINUE =
  "Accepting this will clear your cart. still want to continue?";

// Dine In
export const PLEASE_SCAN_QR_CODE_ON_YOUR_TABLE_TO_PLACE_AN_ORDER =
  "Please Scan QR Code on your table to place an Order";
export const YOU_ARE_SEATED_AT = "You are already seated at";
export const CONTINUE_AND_ORDER = "CONTINUE AND ORDER";
export const FETCHING_RESTAURANT_ID = "Fetching Restaurant...";

// My orders
export const TAX = "Tax";
export const PLEASE_SELECT_TIP_AMOUNT = "Please select Tip Amount";
export const HELP = " Help";
export const AND_HIS_FAMILY_BY_ADDING_TIP = "and his family by adding a tip!";
export const RIDER = " Rider ";
export const SERVER = " Server ";
export const ADD_A_COMPLIMENT = "Add a compliment...";
export const NO_ORDER_HISTORY_AVAILABLE = " No Order History Available";
export const ORDER_TRACKING = "Order Tracking";
export const TIP_RIDER = "Tip Rider";
export const TIP_SERVER = " Tip Server";
export const ADD_TIP = " Add Tip";
export const SKIP = "Skip";
export const RATE_DRIVER = "Rate Driver";
export const RATE_RESTAURANT = "Rate Restaurant";
export const ITEM_RATE = "Item Rate";
export const YOUR_SUGGESTIONS_HERE = "Your Suggestions Here..";
export const WRITE_HERE = "Write here...";

export const COOKING_INSTRUCTION = "Cooking Instruction";
export const ITEM_TOTAL = "Item Total";
export const FILTER = "Filter";
export const VEG = "Veg";
export const NO_VEG = "Non-Veg";
export const VEGAN = "Vegan";
export const SEARCH = "Search";
export const DETECT_NEW_RESTAURANT = "Detect new restaurants";

// Address
export const DELETE_CONFIRMATION = "Delete Confirmation";
export const WOULD_YOU_LIKE_TO_DELETE_DELIVERY_ADDRESS =
  "Would you like to delete delivery address?";
export const UPDATE_CONFIRMATION = "Update Confirmation";
export const WOULD_YOU_LIKE_TO_CHOOSE_DELIVERY_ADDRESS =
  "Would you like to choose this address as delivery address?";
export const WOULD_YOU_LIKE_TO_CHOOSE_CURRENT_ADDRESS_AS_DELIVERY_ADDRESS =
  "Would you like to choose current address as delivery address?";
export const ADD_CURRENT_LOCATION = "Add Current Location";
export const NO_ADDRESS_ARE_AVAILABLE = "No addresses are available";
export const ADD_NEW_ADDRESS = "Add New Address";
export const ADD_DELIVERY_ADDRESS = "Add Delivery Address";

// OFFERS
export const REWARD_DETAILS = "Reward Details";

// Restaurant details
export const DIRECTION = "Directions";
export const RESERVE_A_TABLE = "RESERVE A TABLE";
export const TIMING = "Timing";
export const PHONE_NUMBER = "Phone Number";
export const EMAIL = "Email";
export const FACILITIES = "Facilities";
export const REVIEWS = "Reviews";
export const NO_RUNNING_EVENTS_AVAILABLE = "No Running Events Are Available";
export const UP_COMING_OUTLETS = "Upcoming Outlets";

// Toasts
export const PLEASE_ENTER_PROMO_CODE = "Please enter promo code";
export const VERIFYING_COUPON = "Verifying coupon...";
export const PLEASE_SELECT_AMOUNT_TO_BE_ADDED =
  "Please select amount to be added";
export const PLEASE_SELECT_RESTAURANT = "Please select restaurant";
export const PLEASE_ENTER_VALID_AMOUNT = "Please enter valid amount";
export const PAYING = "Paying...";
export const DELETING_ADDRESS = "Deleting Address...";
export const PLEASE_SELECT_ADDRESS_TYPE = "Please select address type";
export const SAVING_ADDRESS = "Saving address...";
export const DELIVERY_ADDRESS_UPDATED = "Delivery Address Updated";
export const PLEASE_ALLOW_LOCATION = "Please allow location";

// Payment Gateway
export const WALLETS = "Wallets";
export const NO_WALLETS_ARE_AVAILABLE = "No Wallets are available";
export const UPIS = "UPIs";
export const PAY_WITH_UPIS = "Pay with UPI";
export const ONLINE_PAYMENTS = "Online Payments";
export const SAVE_CARD_FOR_FUTURE_USE =
  "After you first payment, we will save your details for a future use.";
export const CARD_DEBIT_ATM_CARD = "Credit Debit & ATM Cards";
export const NET_BANKING = "Net Banking";
export const NO_BACKS_ARE_AVAILABLE = "No banks are available";
export const VIEW_MORE = "View More";
export const VIEW_LESS = "View less";
export const PAYMENT_METHODS = "Payment Methods";
export const CASH_ON_DELIVERY = "Cash on Delivery";
export const CASH = "Cash";
export const PLEASE_LOGIN_FOR_FURTHER_PROCESS =
  "Please login for further process";
export const PAYTM = "Paytm";
export const PYRAMID_HOSPITALITIES = "Pyramid Hospitalities";

// Page Not Found
export const GO_BACK = "Go back";
export const PAGE_NOT_FOUND = "Page Not Found";
export const UN_AUTHORIZED_ACCESS = "Un Authorized Access";
