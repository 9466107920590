import React, { useContext, useEffect, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../reducer/context";
import { confirmAlert } from "react-confirm-alert";
import { LOGOUT, TOGGLE_EDIT_PROFILE_MODAL } from "../../reducer/actions/userActions";
import * as clevertapEvents from "../../clevertapEvents";
import ClevertapReact from "clevertap-react";
import { callGetApi } from "../../api/ApiCaller";
import * as ApiConst from "../../api/ApiConst";
import { NO_ERROR } from "../../api/constants";
import * as colors from '../../utils/colorCodes'
import * as staticConstants from '../../translations/en/en'
import * as AirBridgeUtilities from "../airbridge/AirBridgeUtilities";
import { InformationAlert } from "./InformationAlert";
import { initializeZohoChat } from "../../zohoChat/zohoChatUtils";
import { AlertBox, useWindowSize, profileNavigationTabs, LazyLoadedImage } from "./index";

export const ProfileSideMenu = ({ handleToggleSideMenu }) => {
    const [userDetails, setUserDetails] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isExpanded, setIsExpanded] = useState("");

    const { authState: { isAuthenticated, user } } = useContext(GlobalContext);
    const { dispatchAuth } = useContext(GlobalContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            setIsLoggedIn(false);
            return;
        } else {
            setUserDetails(user);
            setIsLoggedIn(true);
        }
    }, [isAuthenticated, user]);

    // const handleToggleEditProfileModal = () => {
    //     dispatchAuth({ type: TOGGLE_EDIT_PROFILE_MODAL })
    // };

    const handleSideMenu = () => {
        handleToggleSideMenu && handleToggleSideMenu();
    };

    const handleDeleteAccount = () => {
        const handleClose = () => {
            callGetApi(ApiConst.Get_Delete_Account, {}, response => {
                if (response.errorCode === NO_ERROR) {
                    dispatchAuth({ type: LOGOUT });
                    navigate("/");
                    // handleToggleSideMenu && handleToggleSideMenu()
                    InformationAlert({ message: staticConstants.ACCOUNT_DELETED_SUCCESSFULLY })
                    setIsLoggedIn(false);
                    AirBridgeUtilities.userLogoutEvent()
                } else {
                    InformationAlert({ message: response.message })
                }
            })
        }
        AlertBox({
            heading: staticConstants.CONFIRM_DELETION,
            message: staticConstants.ARE_YOU_SURE_YOU_WANT_DELETE,
            handleSuccess: handleClose,
            successButton: staticConstants.DELETE,
        })
    };

    const handleLogout = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                const handleClose = () => {
                    dispatchAuth({ type: LOGOUT });
                    ClevertapReact.logout();
                    clevertapEvents.sideMenuClickEvent(staticConstants.LOGOUT);
                    AirBridgeUtilities.userLogoutEvent()
                    navigate("/");
                    handleToggleSideMenu && handleToggleSideMenu()
                    setIsLoggedIn(false);
                    onClose()
                }
                return (
                    <div className='custom-ui text-center p-3 rounded shadow bg-white' style={{ background: `${colors.WHITE} !important`, width: "350px" }}>
                        <img
                            src={userDetails && userDetails.userImage ? userDetails.userImage : "../img/default-user.png"}
                            alt="profile" className="rounded-circle" style={{ width: "70px", height: " 70px", borderRadius: "50%", objectFit: "cover" }}
                        />
                        <h5 className="font-weight-600 font-size-20 mt-3">{staticConstants.CONFIRM_LOGOUT}</h5>
                        <div className='font-size-14 text-gray font-weight-700 mb-0'>{staticConstants.ARE_YOU_SURE_YOU_WANT_LOGOUT}</div>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <button onClick={handleClose} className="btn border-yellow text-yellow font-weight-800 font-size-16 border-radius-5 " style={{ width: "120px", padding: "14px 0" }}>{staticConstants.YES}</button>
                            <button onClick={onClose} className="btn bg-yellow font-weight-800 font-size-16 border-radius-5 mx-2 px-4" style={{ width: "120px", padding: "14px 0" }}>{staticConstants.CANCEL}</button>
                        </div>
                    </div>
                );
            }
        });
    };

    const callCleverTap = (e, title, subTitle = "") => {
        handleSideMenu(e);
        if (subTitle === staticConstants.DELETE_ACCOUNT) {
            handleDeleteAccount()
        }
        if (title === staticConstants.ONLINE_SUPPORT) {
            initializeZohoChat()
        }

        clevertapEvents.sideMenuClickEvent(title);
    };

    const handleHideClassInBody = () => {
        document.body.classList.remove('showingSideMenu');
    }
    const handleExpand = (id) => setIsExpanded(prev => prev === id ? "" : id)

    return (
        <>
            {isAuthenticated &&
                (!userDetails ? (
                    <div className="order-item-1 p-4 border-radius-5 bg-white">
                        <LoaderIcon />
                    </div>
                ) :
                    <ProfileCard
                        // userImage={userDetails.userImage}
                        // fullName={userDetails.fullName}
                        // mobile={userDetails.mobile}
                        userDetails={userDetails}
                    // onClick={handleToggleEditProfileModal}
                    />
                )}

            <div className="menu-list">
                <ul className="menu-content">
                    {profileNavigationTabs.map((currentTab) => {
                        if (isLoggedIn || (!isLoggedIn && !currentTab.authenticated)) {
                            if (currentTab.isExpandable) {
                                return (
                                    <ExpandableLink
                                        key={currentTab.id}
                                        currentTab={currentTab}
                                        onClick={(e, subTitle) => {
                                            callCleverTap(e, currentTab.title, subTitle)
                                            handleHideClassInBody()
                                        }}
                                        isExpanded={isExpanded}
                                        onExpand={handleExpand}
                                    />
                                );
                            } else {
                                return (
                                    <NonExpandableLink
                                        key={currentTab.id}
                                        currentTab={currentTab}
                                        onClick={(e) => {
                                            callCleverTap(e, currentTab.title)
                                            handleHideClassInBody()
                                        }} />
                                );
                            }
                        }

                    })}
                    {isLoggedIn && (
                        <li className="order-item border-radius-5 bg-white cursor-pointer" onClick={handleLogout}>
                            <div className="d-flex align-items-center gap-2 py-3 px-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.034" height="18.405" viewBox="0 0 21.034 18.405">
                                    <path id="logout" d="M15.525,35.036l5.045,5.045a1.587,1.587,0,0,1,0,2.243l-5.045,5.045a1.394,1.394,0,0,1-2.379-.986V43.832H7.888a1.313,1.313,0,0,1-1.315-1.315V39.888a1.313,1.313,0,0,1,1.315-1.315h5.258V36.022a1.394,1.394,0,0,1,2.379-.986Zm-8.952-.407H3.944a1.313,1.313,0,0,0-1.315,1.315V46.461a1.313,1.313,0,0,0,1.315,1.315H6.573a1.315,1.315,0,1,1,0,2.629H3.944A3.945,3.945,0,0,1,0,46.461V35.944A3.945,3.945,0,0,1,3.944,32H6.573a1.315,1.315,0,1,1,0,2.629Z" transform="translate(0 -32)" fill={colors.PRIMARY_YELLOW} />
                                </svg>
                                <span className="d-flex align-items-center cursor-pointer">
                                    <p className="mb-0 font-size-16 font-weight-700 text-dark-black">{staticConstants.LOGOUT}</p>
                                </span>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
};

// const ProfileCard = ({ userImage, fullName, mobile, onClick }) => {
const ProfileCard = ({ userDetails }) => {
    const { userImage, fullName, mobile } = userDetails
    const { dispatchAuth } = useContext(GlobalContext);
    const handleToggleEditProfileModal = () => {
        dispatchAuth({ type: TOGGLE_EDIT_PROFILE_MODAL })
    };

    return (
        <div
            className="order-item-1 p-4 border-radius-5 bg-white cursor-pointer"
            onClick={handleToggleEditProfileModal}
        >
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-1">
                    {/* <img
                        src={userImage ? userImage : "../img/profile-user.png"}
                        style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "50%",
                            objectFit: "cover"
                        }}
                        alt="user"
                    /> */}
                    <LazyLoadedImage
                        src={userImage ? userImage : "../img/default-user.png"}
                        placeholderSrc="../img/default-user.png"
                        alt="user"
                        wrapperClassName="profile-pic mx-auto"
                        // className="profile-pic mx-auto"
                        style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "50%",
                            objectFit: "cover"
                        }}
                    />
                    <div>
                        <p className="mb-0 font-size-20 font-weight-900 mb-2 text-dark-black">{fullName}</p>
                        <p className="mb-0 text-light-gray font-size-12">+91- {mobile}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const NonExpandableLink = ({ currentTab, onClick }) => {
    const { id, title, path, svgIcon } = currentTab
    const size = useWindowSize();

    return (
        <>
            {(currentTab.plateForm === "BOTH" || (currentTab.plateForm === "ANDROID" && size[0] < 1080)) && <li
                key={id}
                className="order-item border-radius-5 bg-white"
                onClick={(e) => onClick(e)}
            >
                <NavLink
                    className={({ isActive }) => `d-flex align-items-center gap-2 py-3 px-4 ${isActive ? "activelink" : ""}`}
                    to={path && path !== "" && path}
                >
                    {svgIcon}
                    <p className="mb-0 font-size-16 font-weight-700 text-dark-black">{title}</p>
                </NavLink>
            </li>}
        </>
    )
}

const ExpandableLink = ({ currentTab, onClick, onExpand, isExpanded }) => {
    const { id, title, svgIcon } = currentTab

    return (
        <li
            key={id}
            className={`border-radius-5 bg-white cursor-pointer ${isExpanded === id ? "collapsed" : ""}`}
            style={{ marginBottom: "14px" }}
            onClick={() => onExpand(id)}
        >
            <div className="d-flex align-items-center justify-content-between border-radius-5 order-item mb-0 py-3 px-4">
                <div className="d-flex align-items-center gap-2">
                    {svgIcon}
                    <p className="mb-0 font-size-16 font-weight-700 text-dark-black">{title}</p>
                </div>
                <div className="arrow-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.578" height="13.048" viewBox="0 0 21.578 13.048">
                        <g id="Path_102" transform="translate(21.578 13.048) rotate(180)">
                            <path
                                id="Path_102"
                                d="M10.789,0,0,10.789l2.259,2.259,8.53-8.529,8.53,8.529,2.26-2.259Z"
                                transform="translate(0 0)"
                                fill={colors.PRIMARY_YELLOW}
                            />
                        </g>
                    </svg>
                </div>
            </div>
            {
                <>
                    <ul className={`collapse mb-0 ${isExpanded === id ? "show" : ""}`} id={"new" + id}>
                        {currentTab.subTabs.map((currentSubTab, j) => (
                            <li
                                key={j}
                                className=" border-radius-5 bg-white"
                                onClick={(e) => onClick(e, currentSubTab.subTitle)}
                            >
                                <NavLink
                                    className={({ isActive }) => `d-flex align-items-center  justify-content-between border-radius-5 order-item mb-0  py-3 px-4 ${isActive ? "activelink" : ""}`}
                                    to={currentSubTab.subPath}
                                >
                                    <div className="d-flex align-items-center gap-2">
                                        <p className="mb-0 font-size-16 font-weight-700 text-dark-black">{currentSubTab.subTitle}</p>
                                    </div>
                                </NavLink>
                            </li>
                        )
                        )}
                    </ul>
                </>
            }
        </li>)
}

export default ProfileSideMenu;
