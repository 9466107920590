import {
  BOOK_TABLE_NOW,
  FIll_DETAILS,
  GET_OCCASION_LIST,
  GET_RESTAURANT_LIST,
} from "../actions/bookTableActions";

export const bookTableReducer = (state, action) => {

  switch (action.type) {
    case FIll_DETAILS:
      return { ...state, ...action.payload };
    case GET_RESTAURANT_LIST:
      return { ...state, restaurantList: [...action.payload] };
    case GET_OCCASION_LIST:
      return { ...state, occasionList: [...action.payload] };
    case BOOK_TABLE_NOW:
      return { ...state, ...action.payload, isBooked: true };
    default:
      return state;
  }
};
