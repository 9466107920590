import { getToken } from "firebase/messaging";
import { messaging } from "./FirebaseConfig";
// import { toast } from "react-hot-toast";
import * as ApiConst from "../api/ApiConst";
import { callPostApi } from "../api/ApiCaller";
import { NO_ERROR } from "../api/constants";
import DetectOS from "../plateform/DetectOS";
import { InformationAlert } from "../components/common";
import { OOPS } from "../translations/en/en";

const detect = new DetectOS();
// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
export const generateDeviceToken = async () => {
  const deviceToken = await getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID,
  });
  if (deviceToken) {
    // Send the token to your server and update the UI if necessary
    const obj = {
      deviceType: "WEB",
      fcmDeviceToken: deviceToken,
      deviceId: "uuid",
    };
    if (detect.OS === "Android") {
      obj.deviceType = "ANDROID";
    } else if (detect.OS === "macOS" || detect.OS === "iOS") {
      obj.deviceType = "IPHONE";
    } else if (detect.OS === "Windows") {
      obj.deviceType = "WEB";
    }

    callPostApi(ApiConst.Post_Update_Device_Detail, obj, {}, (response) => {
      if (response.errorCode === NO_ERROR) {
      } else {
        InformationAlert({title:OOPS,message:response.message})
      }
    });
  } else {
    // Show permission request UI
    console.log(
      "No registration token available. Request permission to generate one."
    );
  }
};

// Function to show a toast message
const showToast = (message) => {
  // toast(message)
  InformationAlert({ message });
};

// Function to handle notification permission
const handleNotificationPermission = (permission) => {
  if (permission === "granted") {
    generateDeviceToken();
  } else if (permission === "denied") {
    // showToast("You will not receive notifications for upcoming events");
  } else {
    // showToast("You may not receive notifications for upcoming events");
  }
};

// Function to request notification permission
export const requestNotificationPermission = async () => {
  try {
    if ("Notification" in window) {
      let permission;
      if (window.Notification.permission === "default") {
        permission = await window.Notification.requestPermission();
      } else {
        permission = window.Notification.permission;
      }
      handleNotificationPermission(permission);
    } else {
      // showToast("Browser does not support notifications.");
    }
  } catch (error) {
    // showToast("An error occurred while requesting notification permission.");
  }
};

// const verifyNotificationPermission = (permission) => {
//   if (permission === "granted") {
//     generateDeviceToken();
//   } else if (permission === "denied") {
//     toast("You will not get notifications of upcoming events");
//   } else {
//     toast("You may not get notifications of upcoming events");
//   }
// };

// export const requestNotificationPermission = async () => {
//   try {
//     if (detect.browser.identity === "Safari") {
//       Notification.requestPermission(verifyNotificationPermission);
//     } else {
//       const permission = await Notification.requestPermission();
//       verifyNotificationPermission(permission);
//     }
//   } catch (error) {
//     toast("Browser not support notifications!!!");
//   }
// };
