import axios from "axios";
import { useLayoutEffect, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { getParsedValueFromStorage } from "../../utils/GetSetStorage";
import { getDefaultLocation } from "../dashboard/common/DashboardUtils";

export const useCurrentLocation = () => {
  const [position, setPosition] = useState(null);

  useEffect(() => {
    if (!position) {
      getCurrentLocation();
    }
  }, []);

  const getCurrentLocation = async () => {
    const isGeoLocationAvailable = await navigator.permissions.query({
      name: "geolocation",
    });

    if (!isGeoLocationAvailable || !navigator.geolocation) {
      toast("Geolocation is not supported by your browser");
      return;
    }

    // if (isGeoLocationAvailable.state === "granted") {
    //   const userAddress = getParsedValueFromStorage("selectedUserAddress");
    //   setPosition(userAddress);
    //   return;
    // }

    const address = {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      addressType: "",
      latitude: 0,
      longitude: 0,
      recordId: null,
    };

    const options = { timeout: 5000 };
    const onSuccess = (position) => {
      address.latitude = position.coords.latitude;
      address.longitude = position.coords.longitude;
      axios
        .post(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyBwD9qASVx4Jjqei_IZzUPvp3Xdh_5ATOo`
        )
        .then((response) => {
          let formattedAddress = response.data.results[0].formatted_address;
          let removedWord = formattedAddress.split(",")[0];
          const finalAddress = formattedAddress.replace(removedWord + ",", "");
          address.addressLine1 = finalAddress;
          setPosition(address);
        })
        .catch((error) => {
          setPosition(getDefaultLocation());
        });
    };

    const onError = (error) => setPosition(getDefaultLocation());

    navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
  };
  return position;
};

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export const usePosition = () => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  const onChange = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (error) => {
    toast.error(error.message);
    setError(error.message);
  };

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      toast("Geolocation is not supported");
      setError("Geolocation is not supported");
      return;
    }
    const watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);
  return { ...position, error };
};

export const handleShareToOther = (content) => {
  if (!navigator.share) {
    toast.error("can't be shared \n something went wrong");
    return;
  }
  const shareData = {
    title: "Pyramid",
    text: content,
    // url:`${ApiConst.BASE_URL}`,
  };
  try {
    navigator.share(shareData);
  } catch (err) {
    toast.error("can't be shared \n something went wrong");
  }
};

export const handleShareEventToOther = (content="",eventId) => {
  if (!navigator.share) {
    toast.error("can't be shared");
    return;
  }
  const shareData = {
    title: "Pyramid",
    text: content,
    url:`${window.location.href}event-detail/${eventId}`
  };

  try {
    navigator.share(shareData);
  } catch (err) {
    toast.error("Something went wrong while sharing");
  }
};


export const handleCopyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast("Copied", { position: "bottom-center", duration: 1000 });
    })
    .catch(() => {
      toast("😑");
    });
};

export const getRestaurantKeyValuePairs = (restaurantList) => {
  const keyValuePairs = restaurantList.map((listData) => ({
    recordId: listData.recordId,
    value: listData.restaurantUuid,
    label: listData.title,
  }));
  return keyValuePairs;
};

export const vibrateDevice = (time = 200) => {
  // Time in milliSeconds(ms)
  if (window.navigator && window.navigator.vibrate) {
    window.navigator.vibrate(time);
  }
};

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
export const isScriptExist = (src) => {
  return new Promise((resolve) => {
    const scriptList = document.querySelectorAll("script");
    const convertedNodeList = Array.from(scriptList);
    const testScript = convertedNodeList.find((script) => script.src === src);
    if (testScript) {
      resolve(true);
    } else {
      resolve(false);
    }
  });
};

export function removeScript(src) {
  const scriptList = document.querySelectorAll("script");
  const convertedNodeList = Array.from(scriptList);
  const testScript = convertedNodeList.find((script) => script.src === src);
  testScript && testScript.parentNode.removeChild(testScript);
}

export const useMetaData = ({ title, description, keyWords }) => {
  useEffect(() => {
    var headTags = document.getElementsByTagName("META");
    if (title) {
      document.title = title;
    }

    for (let i = 0; i < headTags.length; i++) {
      const name = headTags[i].name;
      if (description && name === "description") {
        headTags[i].content = description;
      } else if (keyWords && name === "keywords") {
        headTags[i].content = keyWords;
      } else if (name === "author") {
        headTags[i].content = "";
      }
    }
  }, []);

  return;
};
