import airbridge from 'airbridge-web-sdk-loader'
import { AirBridge_AppName, AirBridge_WebToken } from './airbridgeConfig'

export const initializeAirBridge = (appName=AirBridge_AppName, webToken=AirBridge_WebToken) => {
    if(!airbridge.isSDKEnabled){
        airbridge.init({
            app: appName,
            webToken: webToken,
        })
    }
}

export const userSignInEvent = (userDetails) => {
    const { mobile, recordId, uuid } = userDetails
    airbridge.events.signIn({
        userID: uuid,
        userEmail: userDetails?.email ? userDetails?.email : "",
        userPhone: "+91" + mobile,
        attributes: {
            gender:userDetails?.gender
        },
        alias: {
            recordId
        },
    });
    airbridge.events.send('airbridge.user.signIn');
}

export const loginEvent = (userDetails) => {
    const { mobile } = userDetails
    airbridge.events.send("AB_LOGIN", {
        label: "AB_LOGIN",
        action: "AB_LOGIN",
        value: "+91" + mobile
    });
}

export const orderCompletedEvent = (orderDetails, transactionID) => {
    const { itemList, orderTotal } = orderDetails
    let products = []
    for (let i = 0; i > itemList.length; i++) {
        const { itemId, title, price, quantity } = orderDetails.itemList[i]
        products.push({
            productID: itemId,
            name: title,
            price: price,
            currency: 'INR',
            quantity: quantity,
            position: i
        })
    }
    airbridge.events.send('airbridge.ecommerce.order.completed', {
        semanticAttributes: {
            products: [...products],
            inAppPurchased: true,
            totalValue: orderTotal,
            currency: 'INR',
            transactionID: transactionID
        }
    });
}


export const sendCleverTapId = (cleverTapId) => {
    airbridge.setDeviceAlias("clevertap_device_id", cleverTapId)
}

export const userLogoutEvent = () => {
    airbridge.events.send('airbridge.user.signout');
    airbridge.clearUser();
}