
import { lazy } from 'react';

export const Ceo = lazy(() => import('./Ceo'));
export const ContactUs = lazy(() => import('./ContactUs'));
export const Franchise = lazy(() => import('./franchisee/Franchise'));
export const JobAndCareer = lazy(() => import('./JobAndCareer'));
export const NotFound = lazy(() => import('./404'));
export const AtmBar = lazy(() => import('./AtmBar'));
export const ViewRestaurants = lazy(() => import('./ViewRestaurants'));
export const Login = lazy(() => import('./login/Login'));
export const LoginWithFaceBook = lazy(() => import('./login/LoginWithFaceBook'));
export const OtpLessLogin = lazy(() => import('./login/OtpLessLogin'));
export const RestaurantDetail = lazy(() => import('./RestaurantDetail'));
export const PageWrapper = lazy(() => import('./common/PageWrapper'));
export const StaticPages = lazy(() => import('./common/StaticPages'));
export const Checkout = lazy(() => import('./checkout/Checkout'));
export const LoginModal = lazy(() => import('./login/LoginModal'));