import React from 'react'

export const Loader = () => {
  return (
    <div className='loading-details'>
      <div className='loaderIcon'></div>
    </div>

  )
}
export default Loader