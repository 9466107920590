import React from 'react'
import Carousel from "./Carousel"

export const DashboardWrapper = ({ children }) => {
    return (
        <>
            <div className="osahan-home-page landing-page-mt">
                <section className='custom_home_slider'>
                    <Carousel id="mainSlider" bannerType="DashboardTop" />
                </section>
                {children}
                {/* <section className="categories-desktop">
                    <Carousel id="bottomSlider" bannerType="DashboardBottom" />
                </section> */}
            </div>
        </>
    )
}

export default DashboardWrapper;