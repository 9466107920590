import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { handleGoBack } from '../dashboard/common/DashboardUtils'

export const NavHeader = ({ children }) => {
    const navigate = useNavigate()
    const location = useLocation()
    
    return (
        <>
            <div className="d-lg-none d-block  bg-white  py-2 arrow-header" >
                <div className="row align-items-center mx-auto box-shadow-sm justify-content-center ">
                    <div className="arrow-left ">
                        <span onClick={()=>handleGoBack(location,navigate)} role="button" aria-controls="hc-nav-1">
                            <img src="../img/Vector.png" alt="Vector" width="20px" />
                        </span>
                    </div>
                    <div className="col-12">
                        <h2 className="mb-0 font-size-20 font-weight-700 text-center">
                            {children ? children : ""}
                        </h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavHeader
