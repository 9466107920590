import {
  CLEAR_USER_CART,
  SET_LOADING_FALSE,
  SET_LOADING_TRUE,
  SET_ORDER_TYPE,
  SET_RESTAURANT_ID,
  SET_RESTAURANT_LIST,
  SET_SELECTED_RESTAURANT,
  SET_USER_CART,
} from "../actions/utilityActions";

export const utilityReducer = (state, action) => {
  switch (action.type) {
    case SET_USER_CART:
      localStorage.setItem("userCart", JSON.stringify(action.payload));
      return {
        ...state,
        userCart: action.payload,
      };
    case CLEAR_USER_CART:
      localStorage.removeItem("userCart");
      return {
        ...state,
        userCart: null,
      };
    case SET_ORDER_TYPE:
      localStorage.setItem("orderType", JSON.stringify(action.payload));
      return {
        ...state,
        orderType: action.payload,
      };
    case SET_RESTAURANT_LIST:
      return {
        ...state,
        restaurantList: action.payload,
      };
    case SET_RESTAURANT_ID:
      localStorage.setItem(
        "restaurantId",
        JSON.stringify(action.payload.restaurantId)
      );
      return {
        ...state,
        restaurantId: action.payload.restaurantId,
        restaurantName: action.payload.restaurantName,
        restaurantRecordId: action.payload.restaurantRecordId,
      };
    case SET_SELECTED_RESTAURANT:
      localStorage.setItem(
        "restaurantId",
        JSON.stringify(action.payload.value)
      );
      return {
        ...state,
        selectedHeaderRestaurant: {
          value: action.payload.value,
          label: action.payload.label,
          recordId: action.payload.recordId,
        },
        restaurantId: action.payload.value,
        restaurantName: action.payload.label,
        restaurantRecordId: action.payload.recordId,
      };
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
