import { _BOOKNOW } from "../../translations/en/en";

export const bookTableInitialState = {
  restaurant: "",
  reservedByName: "",
  reservedByMobile: "",
  reservedByEmail: "",
  reservationDateTime: "",
  occasion: "",
  numberOfPeople: "",
  cookingInstructions: "",
  eventId: "0",
  isBooked: false,
  restaurantList: [],
  occasionList: [],
};

export const initialFranchise = {
  firstName: "",
  lastName: "",
  companyName: "",
  country: "",
  state: "",
  city: "",
  mobileNumber1: "",
  mobileNumber2: "",
  email: "",
};

export const jobAndCareerInitialState = {
  firstName: "",
  lastName: "",
  email: "",
  dateOfBirth: "",
  mobile: "",
  education: "",
  gender: "",
  address: "",
  country: "",
  state: "",
  city: "",
  postalCode: "",
};

export const feedbackInitialState = {
  customerName: "",
  customerMobile: "",
  selectedRestaurant: "",
  qualityOfFood: "",
  serviceQuality: "",
  cleaning: "",
  staffBehavior: "",
  music: "",
  bestPartOfRestaurant: "",
  suggestion: "",
  restaurantList: [],
};

export const initialUserDetail = {
  fullName: "",
  userImage: "",
  mobile: "",
  mobileVerified: true,
  email: "",
  emailVerified: false,
  gender: "",
  dateOfBirth: "",
  anniversaryDate: "",
  socialLoginFrom: "",
};

export const initialStealDealDetails = {
  itemList: [],
  categoryList: [],
  mainCategory: _BOOKNOW,
  categoryType: null,
  isFetching: false,
  showStealDealDetails: false,
  selectedItemId: "",
  scannerArea: false,
  QRcodeString: null,
  selectedConsumedItem: null,
  consumeItemQty: 1,
  showGiftToSomeone: false,
  isViewDetailsOrCart: true,
  giftCode: { show: false, giftCodeText: "" },
};
export const registerDetails = {
  isdCode: "+91",
  mobileNumber: "",
  otp: "",
  facebookUserAccessToken: "",
  facebookUserId: "",
};
