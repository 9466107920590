import axios from "axios";
import axiosRetry from "axios-retry";
import Cookies from "universal-cookie";
import { UNAUTHORIZED, UNAUTHORIZED_MESSAGE } from "./constants";
import { InformationAlert } from "../components/common";
import { OOPS, SOMETHING_WENT_WRONG } from "../translations/en/en";
import * as clevertapEvents from "../clevertapEvents";

axiosRetry(axios, { retries: 3 });

const getHeaders = (headerMap) => {
  const authToken = new Cookies().get("authToken");
  if (!authToken) {
    return headerMap;
  }
  headerMap["Authorization"] = authToken.accessToken || "Basic fake";
  headerMap["Content-Type"] ="application/json";
  return headerMap;
};

const handleSuccess = (result, successCallBack) => {
  if (successCallBack != null) {
    if (
      result.data.errorCode === UNAUTHORIZED ||
      result.data.message === UNAUTHORIZED_MESSAGE
    ) {
      const cookies = new Cookies();
      cookies.remove("authToken");
      cookies.remove("user");
      localStorage.clear();
      window.location.href = "/";
    }
    successCallBack(result.data);
  }
};

const handleFailure = (
  error,
  failedCallBack,
  requestUrl,
  requestObj,
  headers
) => {
  if (failedCallBack != null) {
    failedCallBack(error);
  }
  const errorObj = {
    requestUrl: requestUrl,
    headers: JSON.stringify(headers),
    requestObj: JSON.stringify(requestObj),
    error: JSON.stringify(error),
  };
  clevertapEvents.errorLogs(errorObj);
  if (error.response) {
    // client received an error response (5xx, 4xx)
    const message = error.response?.data?.message;
    InformationAlert({ title: OOPS, message });
  } else if (error.request) {
    // client never received a response, or request never left
    const message = "Sever is not responding";
    InformationAlert({ title: OOPS, message });
  } else {
    // anything else
    const message = SOMETHING_WENT_WRONG;
    InformationAlert({ title: OOPS, message });
  }
};

export const callPostApiWithoutAuth = (
  url,
  data,
  successCallBack,
  failedCallBack
) => {
  const _url = url;
  const _data = data;
  const _headerMap = "NA";
  axios
    .post(url, data)
    .then((result) => handleSuccess(result, successCallBack))
    .catch((error) => {
      if (error.request && !error.response) {
        callPostApiWithoutAuth(url, data, successCallBack, failedCallBack);
        return;
      }
      handleFailure(error, failedCallBack, _url, _data, _headerMap);
    });
};

export const callGetApi = (url, headerMap, successCallBack, failedCallBack) => {
  const _url = url;
  const _data = "NA";
  const _headerMap = getHeaders(headerMap);
  axios
    .get(url, {
      headers: getHeaders(headerMap),
      timeout: 30000,
    })
    .then((result) => handleSuccess(result, successCallBack))
    .catch((error) => {
      if (error.request && !error.response) {
        callGetApi(url, headerMap, successCallBack, failedCallBack);
        return;
      }
      handleFailure(error, failedCallBack, _url, _data, _headerMap);
    });
};

export const callPostApi = (
  url,
  data,
  headerMap,
  successCallBack,
  failedCallBack
) => {
  const _url = url;
  const _data = data;
  const _headerMap = getHeaders(headerMap);
  axios
    .post(url, data, {
      headers: getHeaders(headerMap),
      timeout: 30000,
    })
    .then((result) => handleSuccess(result, successCallBack))
    .catch((error) => {
      if (error.request && !error.response) {
        callPostApi(url, data, headerMap, successCallBack, failedCallBack);
        return;
      }
      handleFailure(error, failedCallBack, _url, _data, _headerMap);
    });
};

export const callPutApi = (
  url,
  data,
  headerMap,
  successCallBack,
  failedCallBack
) => {
  const _url = url;
  const _data = data;
  const _headerMap = getHeaders(headerMap);
  axios
    .put(url, data, {
      headers: getHeaders(headerMap),
      timeout: 30000,
    })
    .then((result) => handleSuccess(result, successCallBack))
    .catch((error) => {
      if (error.request && !error.response) {
        callPutApi(url, data, headerMap, successCallBack, failedCallBack);
        return;
      }
      handleFailure(error, failedCallBack, _url, _data, _headerMap);
    });
};

export const callPatchApi = (
  url,
  data,
  headerMap,
  successCallBack,
  failedCallBack
) => {
  const _url = url;
  const _data = data;
  const _headerMap = getHeaders(headerMap);
  axios
    .patch(url, data, {
      headers: getHeaders(headerMap),
      timeout: 30000,
    })
    .then((result) => handleSuccess(result, successCallBack))
    .catch((error) => {
      if (error.request && !error.response) {
        callPatchApi(url, data, headerMap, successCallBack, failedCallBack);
        return;
      }
      handleFailure(error, failedCallBack, _url, _data, _headerMap);
    });
};

export const callDeleteApi = (
  url,
  headerMap,
  successCallBack,
  failedCallBack
) => {
  const _url = url;
  const _data = "NA";
  const _headerMap = getHeaders(headerMap);
  axios
    .delete(url, {
      headers: getHeaders(headerMap),
      timeout: 30000,
    })
    .then((result) => handleSuccess(result, successCallBack))
    .catch((error) => {
      if (error.request && !error.response) {
        callDeleteApi(url, headerMap, successCallBack, failedCallBack);
        return;
      }
      handleFailure(error, failedCallBack, _url, _data, _headerMap);
    });
};

// import axios from "axios";
// import Cookies from "universal-cookie";
// import { UNAUTHORIZED, UNAUTHORIZED_MESSAGE } from "./constants";
// import { InformationAlert } from "../components/common";
// import { OOPS, SOMETHING_WENT_WRONG } from "../translations/en/en";
// import * as clevertapEvents from "../clevertapEvents";

// const requestHeader={
//     method: "Get",
//     mode: "cors",
//     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
//     credentials: "same-origin", // include, *same-origin, omit
//     headers: {
//       "Content-Type": "application/json",
//       // 'Content-Type': 'application/x-www-form-urlencoded',
//       Authorization:""
//     },
//     redirect: "follow", // manual, *follow, error
//     referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//   }

// const getHeaders = () => {
//   const authToken = new Cookies().get("authToken");
//   if (!authToken) {
//     return {
//     "Content-Type": "application/json;charset=UTF-8",
//   };
//   }
//   return {
//     "Content-Type": "application/json;charset=UTF-8",
//     "Authorization":authToken.accessToken
//   };
// };

// const handleSuccess = (result, successCallBack) => {
//   if (successCallBack != null) {
//     if (
//       result.errorCode === UNAUTHORIZED ||
//       result.message === UNAUTHORIZED_MESSAGE
//     ) {
//       const cookies = new Cookies();
//       cookies.remove("authToken");
//       cookies.remove("user");
//       localStorage.clear();
//       window.location.href = "/";
//     }
//     successCallBack(result);
//   }
// };

// const handleFailure = (error,failedCallBack,requestUrl,requestObj,headers) => {
//   if (failedCallBack != null) {
//     failedCallBack(error);
//   }
//   const errorObj = {
//     "requestUrl":requestUrl,
//     "headers":JSON.stringify(headers),
//     "requestObj":JSON.stringify(requestObj),
//     "error":JSON.stringify(error),
//     "fetch error":JSON.stringify(error)
//   };

//   clevertapEvents.errorLogs(errorObj);
//   if (error.response) {
//     // client received an error response (5xx, 4xx)
//     const message = error.response?.data?.message;
//     InformationAlert({ title: OOPS, message });
//   } else if (error.request) {
//     // client never received a response, or request never left
//     const message = "Sever is not responding";
//     InformationAlert({ title: OOPS, message });
//   } else {
//     // anything else
//     const message = SOMETHING_WENT_WRONG+" in catch";
//     InformationAlert({ title: OOPS, message });
//     console.log("Unknown error", error);
//   }
// };

// const notOkResponse=(res,requestUrl,requestObj,headers)=>{
//   const message = SOMETHING_WENT_WRONG+"(not ok)";
//     InformationAlert({ title: OOPS, message });
//     const errorObj = {
//       "requestUrl":requestUrl,
//       "headers":JSON.stringify(headers),
//       "requestObj":JSON.stringify(requestObj),
//       "error":"Not axios",
//       "fetch error":JSON.stringify(res),
//     };
//     console.log("error:", errorObj);

//     clevertapEvents.errorLogs(errorObj);
// }

// export const callPostApiWithoutAuth = (url,data,successCallBack,failedCallBack) => {
//   const _url = url;
//   const _data = data;
//   const _headerMap = "NA";
//   requestHeader.method="POST"
//   requestHeader.body= JSON.stringify(data)

//   fetch(url,requestHeader).then((res)=>{
//     if (!res.ok) {
//       notOkResponse(res,_url, _data, _headerMap)
//       // throw new Error("Network response was not OK");
//       return
//     }
//     return res.json()

//   }).then((response)=>{
//     handleSuccess(response, successCallBack)
//   }).catch((error)=>{
//     handleFailure(error, failedCallBack, _url, _data, _headerMap)
//   });

// };

// export const callGetApi = (url, headerMap, successCallBack, failedCallBack) => {
//   const _url = url;
//   const _data ="NA";
//   const _headerMap = getHeaders(headerMap);
//   requestHeader.method="GET"
//   requestHeader.headers=getHeaders(headerMap)
//   delete requestHeader.body;

//   fetch(url,requestHeader).then((res)=>{
//     if (!res.ok) {
//       notOkResponse(res,_url, _data, _headerMap)
//       // throw new Error("Network response was not OK");
//       return
//     }
//     const contentType = res.headers.get('Content-Type');
//     if(contentType.includes("text/html")){
//       return res.text()
//     }else{
//       return res.json()
//     }

//   }).then((response)=>{
//     handleSuccess(response, successCallBack)
//   }).catch((error)=>{
//     if (failedCallBack != null) {
//       failedCallBack(error);
//         return
//     }
//     handleFailure(error, failedCallBack, _url, _data, _headerMap)
//   });

// };

// export const callPostApi = (url,data,headerMap,successCallBack,failedCallBack) => {
//   const _url = url;
//   const _data = data;
//   const _headerMap = getHeaders(headerMap);
//   requestHeader.method="POST"
//   requestHeader.headers=getHeaders(headerMap)
//   requestHeader.body= JSON.stringify(data)

//   fetch(url,requestHeader).then((res)=>{
//     if (!res.ok) {
//       notOkResponse(res,_url, _data, _headerMap)
//       // throw new Error("Network response was not OK");
//       return
//     }
//     return res.json()

//   }).then((response)=>{
//     handleSuccess(response, successCallBack)
//   }).catch((error)=>{
// if (failedCallBack != null) {
//   failedCallBack(error);
//     return
// }
//     handleFailure(error, failedCallBack, _url, _data, _headerMap)
//   });

// };

// export const callPutApi = (url,data,headerMap,successCallBack,failedCallBack) => {
//   const _url = url;
//   const _data = data;
//   const _headerMap = getHeaders(headerMap);
//   requestHeader.method="PUT"
//   requestHeader.headers=getHeaders(headerMap)
//   requestHeader.body= JSON.stringify(data)

//   fetch(url,requestHeader).then((res)=>{
//     if (!res.ok) {
//       notOkResponse(res,_url, _data, _headerMap)
//       // throw new Error("Network response was not OK");
//       return
//     }
//     return res.json()

//   }).then((response)=>{
//     handleSuccess(response, successCallBack)
//   }).catch((error)=>{
//     handleFailure(error, failedCallBack, _url, _data, _headerMap)
//   });

// };

// export const callPatchApi = (url,data,headerMap,successCallBack,failedCallBack) => {
//   const _url = url;
//   const _data = data;
//   const _headerMap = getHeaders(headerMap);
//   requestHeader.method="PATCH"
//   requestHeader.headers=getHeaders(headerMap)
//   requestHeader.body= JSON.stringify(data)

//   fetch(url,requestHeader).then((res)=>{
//     if (!res.ok) {
//       notOkResponse(res,_url, _data, _headerMap)
//       // throw new Error("Network response was not OK");
//       return
//     }
//     return res.json()

//   }).then((response)=>{
//     handleSuccess(response, successCallBack)
//   }).catch((error)=>{
//     handleFailure(error, failedCallBack, _url, _data, _headerMap)
//   });

// };

// export const callDeleteApi = (url,headerMap,successCallBack,failedCallBack) => {
//   const _url = url;
//   const _data = "NA";
//   const _headerMap = getHeaders(headerMap);
//   requestHeader.method="DELETE"
//   requestHeader.headers=getHeaders(headerMap)
//   delete requestHeader.body;

//   fetch(url,requestHeader).then((res)=>{
//     if (!res.ok) {
//       notOkResponse(res,_url, _data, _headerMap)
//       // throw new Error("Network response was not OK");
//       return
//     }
//     return res.json()

//   }).then((response)=>{
//     handleSuccess(response, successCallBack)
//   }).catch((error)=>{
//     handleFailure(error, failedCallBack, _url, _data, _headerMap)
//   });

// };
