import { lazy } from "react";

export const ProfileLayout = lazy(() => import("./ProfileLayout"));
export const MyOrders = lazy(() => import("./myOrders/MyOrders"));
export const OrderDetails = lazy(() => import("./myOrders/OrderDetails"));
export const Address = lazy(() => import("./Address"));
export const Wallet = lazy(() => import("./wallet/Wallet"));
export const OfferAndReward = lazy(() => import("./OfferAndReward"));
export const Feedback = lazy(() => import("./Feedback"));
export const Notifications = lazy(() => import("./Notifications"));
export const Settings = lazy(() => import("./Settings"));
export const NotificationSetting = lazy(() => import("./NotificationSetting"));
export const SideBarAddress = lazy(() => import("./SideBarAddress"));
export const OrderTracking = lazy(() => import("./myOrders/OrderTracking"));
