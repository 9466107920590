import React, { Suspense, useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { Ceo, ContactUs, JobAndCareer, NotFound, AtmBar, ViewRestaurants, Login, StaticPages, Franchise, RestaurantDetail, Checkout, LoginModal } from './components/pages';
import { Feedback, MyOrders, Notifications, NotificationSetting, OfferAndReward, OrderTracking, ProfileLayout, Settings, SideBarAddress, Wallet } from './components/profile';
import { EventDetailsPage } from './components/dashboard/tabs';
import { PaymentMethods, Dashboard, Layout, EditProfile, BookTableModal, ModalBox } from "./components"

import { GlobalContext } from './reducer/context';
import { callGetApi, callPostApi } from './api/ApiCaller';
import * as staticConstants from './translations/en/en';
import { SET_RESTAURANT_LIST } from './reducer/actions/utilityActions';
import * as ApiConst from './api/ApiConst';
import { EDIT_PROFILE, TOGGLE_EDIT_PROFILE_MODAL } from './reducer/actions/userActions';
import { NO_ERROR } from './api/constants';
import { useWindowSize } from './components/common/customHooks';
import { checkAvailableNearByOrderType} from './components/dashboard/common/DashboardUtils';
import { InformationAlert, PageLoader } from './components/common';

let retryCount = 0

const RouteConfig = () => {
    const { authState, userLocation, dispatchAuth, utilityStates, dispatchUtility } = useContext(GlobalContext);

    // const currentLocation = useCurrentLocation();
    const { isAuthenticated } = authState;
    const { latitude, longitude } = userLocation;
    const size = useWindowSize();

    const [searchParams] = useSearchParams();
    const queryParamsKeyValuePairs = Object.fromEntries([...searchParams])

    const getAllRestaurants = () => {
        if (!utilityStates.restaurantList) {
            const restaurantPayloadObj = {
                categoryId: 0,
                cityId: 0,
                latitude: latitude,
                length: 1000,
                longitude: longitude,
                orderType: staticConstants.ALL,
                searchKey: '',
                start: 0,
            };
            callPostApi(ApiConst.Post_Restaurant_List, restaurantPayloadObj, {}, (response) => {
                if (response.errorCode === NO_ERROR) {
                    if (!queryParamsKeyValuePairs.tableId && !queryParamsKeyValuePairs.orderType) {
                        checkAvailableNearByOrderType(response.responsePacket, dispatchUtility);
                    }
                    dispatchUtility({ type: SET_RESTAURANT_LIST, payload: response.responsePacket });
                } else {
                    InformationAlert({ message: response.message });
                }
            }, error => {
                retryCount += 1
                if (retryCount <= 3) {
                    getAllRestaurants()
                }
            });
        }
    }

    const toggleProfileModal = () => {
        dispatchAuth({ type: TOGGLE_EDIT_PROFILE_MODAL })
    }

    // useEffect(() => {
    //     if (currentLocation) {
    //         dispatchLocation({ type: SET_LOCATION, payload: currentLocation });
    //     }
    // }, [currentLocation]);

    useEffect(() => {
        // getting restaurant list
        if (latitude && longitude) {
            getAllRestaurants()
        }
    }, [latitude, longitude, utilityStates.restaurantList]);

    useEffect(() => {
        if (isAuthenticated) {
            callGetApi(ApiConst.Get_Profile_Details, {}, (response) => {
                if (response.errorCode === NO_ERROR) {
                    dispatchAuth({ type: EDIT_PROFILE, payload: response.responsePacket });
                } else {
                    toast.error(response.message);
                }
            });
            // TODO
            // clevertapEvents.locationEvent(currentLocation)
        }
    }, [isAuthenticated]);




    return (
        <>
            <Suspense fallback={<PageLoader />}>
                <Routes>
                    <Route path='/' element={<Layout />}>
                        <Route index element={<Dashboard />} />
                        <Route path='ceo' element={<Ceo />} />
                        <Route path='contact-us' element={<ContactUs />} />
                        <Route path='franchise' element={<Franchise />} />
                        <Route path='job-and-career' element={<JobAndCareer />} />
                        {/* <Route path='about-us' element={<StaticPages pageTitle='About Us' pagePath='AboutApp' />} /> */}
                        {/* <Route path='online-support' element={<StaticPages pageTitle='Chat With Us' pagePath='HelpAndSupport' />} /> */}
                        <Route path='privacy-policy' element={<StaticPages pageTitle='Privacy Policy' pagePath='PrivacyPolicy' />} />
                        <Route path='terms-and-conditions' element={<StaticPages pageTitle='Terms And Conditions' pagePath='TermsAndCondition' />} />
                        <Route path='atm-bar' element={<AtmBar />} />
                        <Route path='checkout' element={<Checkout />} />
                        <Route path='restaurants' element={<ViewRestaurants />} />
                        <Route path='restaurants/:restaurantUuid' element={<RestaurantDetail />} />
                        <Route path='event-detail/:eventId' element={<EventDetailsPage />} />
                        <Route path='payment-methods' element={<PaymentMethods />} />
                        <Route path='track-order' element={<OrderTracking />} />
                        <Route element={<ProfileLayout />}>
                            <Route path='my-orders' element={<MyOrders />} />
                            <Route path='address' element={<SideBarAddress />} />
                            <Route path='wallet-money' element={<Wallet />} />
                            <Route path='offers' element={<OfferAndReward />} />
                            <Route path='feedback' element={<Feedback />} />
                            <Route path='notifications' element={<Notifications />} />
                            <Route path='notifications-setting' element={<NotificationSetting />} />
                            <Route path='settings' element={<Settings />} />
                        </Route>
                    </Route>
                    {size[0] <= 1080 ? <Route path='/login' element={isAuthenticated ? <Navigate to={"/"} /> : <Login />} /> : <Route path='/login' element={<Navigate to={"/"} />} />}
                    {/* {size[0] <= 1080 ? <Route path='/login' element={isAuthenticated ? <Navigate to={-1} /> : <Login />} /> : <Route path='/login' element={<Navigate to={-1} />} />} */}
                    <Route path='*' element={<NotFound title={staticConstants.PAGE_NOT_FOUND} />} />
                </Routes>
                
                {authState.showEditProfileModal && <ModalBox
                    headerTitle={Boolean(authState.user?.fullName) ? staticConstants.UPDATE_PROFILE : staticConstants.ADD_PROFILE}
                    show={authState.showEditProfileModal}
                    toggleModel={toggleProfileModal}
                    // shouldCloseOnOverlayClick={!Boolean(authState.user?.fullName)}
                ><EditProfile /></ModalBox>}
                {authState.showLoginModal && <LoginModal />}
                {authState.showBookTableModal && <BookTableModal />}

            </Suspense>
        </>
    );
};

export default RouteConfig;
