import { Component } from 'react';

class TextUtils extends Component {

    isEmpty = (val) => {
        if (val === null || val === undefined || val === "" || val === 0) {
            return true;
        }
        return false;
    }

    isNumeric = (str) => {
        if (typeof str != "string") {
            return false; // we only process strings!  
        }
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    getImageSrc = (imgSrc) => {
        if (this.isEmpty(imgSrc)) {
            // if imgSrc is empty then set default image.
            return "/assets/images/001/user.png";
        }
        return imgSrc;
    }

    isMobile = (mobile) => {
        const regEx = /^[6-9]\d{9}$/
        return regEx.test(mobile)

    }
    isEmail = (email) => {
        // const regEx ="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
        const regEx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return regEx.test(email)
    }
    isUpiId = (upiId) => {
        // const regEx = /^[a-zA-Z0-9\.\-]\@[a-zA-Z][a-zA-Z]*$/
        const regEx = /[a-zA-Z0-9.\\-_]{2,256}@[a-zA-Z]{2,64}/
        return regEx.test(upiId)
    }
}
const textUtils = new TextUtils()

export default textUtils;