import React, { useEffect, useState } from 'react';
import DetectOS from '../../plateform/DetectOS';

const detect = new DetectOS()
const os = detect.OS

export const AppSuggestionPopUp = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const haveAskedAppSuggestion = sessionStorage.getItem(" ")
        if (os !== "Windows" && os !== "macOS" && !!haveAskedAppSuggestion) {
            sessionStorage.setItem("haveAskedAppSuggestion", true)
            setIsOpen(true)
        }
    }, [])

    const closePopup = () => setIsOpen(false);

    const getBrowserName = () => {
        const userAgent = navigator.userAgent;
        if (userAgent.indexOf("Opera") !== -1 || userAgent.indexOf("OPR") !== -1) {
            return "Opera";
        } else if (userAgent.indexOf("Edg") !== -1) {
            return "Edge";
        } else if (userAgent.indexOf("Brave") !== -1 && userAgent.indexOf("Chrome") === -1) {
            return "Brave";
        } else if (userAgent.indexOf("Chrome") !== -1 && userAgent.indexOf("Brave") === -1) {
            return "Chrome";
        } else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1) {
            return "Safari";
        } else if (userAgent.indexOf("Firefox") !== -1) {
            return "Firefox";
        } else if (userAgent.indexOf("MSIE") !== -1 || !!document.documentMode) {
            return "IE";
        } else {
            return "Browser";
        }
    }

    return (
        <>
            <div className={`custom-modal ${isOpen ? 'open' : ''}`}>
                <div className='custom-modal-content'>
                    <div className='font-size-18 mb-3'>See Pyramid Cafe in...</div>
                    <div className='px-3'>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <div className='d-flex align-items-center'>
                                <span className='border p-1 rounded-lg'>
                                    <img src="/favicon.ico" alt="Pyramid VIP" width="50" height="50" style={{ borderRadius: "50%" }} />
                                </span>
                                <span className='ml-2'>Pyramid VIP</span>
                            </div>
                            <div className='col-4 p-0'>
                                <button className="btn bg-yellow text-black font-size-15 d-block px-4 w-100" style={{ borderRadius: "15px" }} onClick={closePopup}>
                                    <a className="text-black" href="https://abr.ge/zb91n8">Open</a>
                                </button>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                                <span className='border p-1 rounded-lg'>
                                    <img src="/img/default-browser.png" alt="Browser" width="50" height="50" style={{ borderRadius: "50%" }} />
                                </span>
                                <span className='ml-2'>{getBrowserName()}</span>
                            </div>
                            <div className='col-4 p-0'>
                                <button className="btn bg-yellow text-black font-size-15 d-block w-100 px-4" style={{ borderRadius: "15px" }} onClick={closePopup}>
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <button onClick={closePopup}>
                            {
                                os === "iOS" ?
                                    <a className="" href="https://apps.apple.com/in/app/pyramid-vip/id1442838211">Open in App</a> :
                                    <a className="" href="https://play.google.com/store/apps/details?id=com.pyramid.gold">Open in App</a>

                            }
                        </button> */}
                </div>
            </div>
        </>
    );
};
