import * as staticConstants from '../../translations/en/en';
import * as colors from '../../utils/colorCodes';

export const profileNavigationTabs = [
    {
        id: 1,
        title: staticConstants.MY_ORDERS,
        authenticated: true,
        path: '/my-orders',
        isExpandable: false,
        plateForm: 'BOTH',
        svgIcon: (
            <svg xmlns='http://www.w3.org/2000/svg' width='29.184' height={25} viewBox='0 0 29.184 25'>
                <g id='icon_18_' transform='translate(-21.498 -32.325)'>
                    <path
                        id='Path_102'
                        data-name='Path 102'
                        d='M36.083,147.408H23.264a1.753,1.753,0,0,1-1.765-1.727,1.7,1.7,0,0,1,1.689-1.842c3.761-.019,7.542-.038,11.3-.038,4.8,0,9.576.019,14.373,0a1.953,1.953,0,0,1,1.785,1.4,1.868,1.868,0,0,1-1.209,2.169.845.845,0,0,1-.307.038Z'
                        transform='translate(0 -90.083)'
                        fill={colors.PRIMARY_YELLOW}
                    />
                    <path
                        id='Path_102'
                        data-name='Path 102'
                        d='M56.417,49A12.8,12.8,0,0,0,46.15,38.058c-.077-.019-.173-.038-.249-.058a.37.37,0,0,1-.288-.365V36c.173-.038.326-.077.48-.115a1.822,1.822,0,0,0-.23-3.55q-2.015-.029-4.03,0a1.755,1.755,0,0,0-1.689,1.574,1.812,1.812,0,0,0,1.286,1.957c.154.038.307.077.5.115,0,.595.019,1.151-.019,1.727,0,.1-.173.23-.288.249a12.669,12.669,0,0,0-9.307,6.908,11.93,11.93,0,0,0-1.324,5.815,1.744,1.744,0,0,0,1.593,1.689h22.2a1.72,1.72,0,0,0,1.689-1.631A8.959,8.959,0,0,0,56.417,49Zm-4.663.768h-.077a.7.7,0,0,1-.672-.595,8.173,8.173,0,0,0-4.3-6.2.665.665,0,0,1,.557-1.209A9.5,9.5,0,0,1,52.329,49a.675.675,0,0,1-.576.768Z'
                        transform='translate(-7.668)'
                        fill={colors.PRIMARY_YELLOW}
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 2,
        title: staticConstants.LOCATIONS,
        authenticated: false,
        path: '/restaurants',
        isExpandable: false,
        plateForm: 'BOTH',
        svgIcon: (
            <svg id='placeholder' xmlns='http://www.w3.org/2000/svg' width='21.936' height={30} viewBox='0 0 21.936 30'>
                <g id='Group_10' data-name='Group 10'>
                    <path
                        id='Path_102'
                        data-name='Path 102'
                        d='M79.785,0A11.08,11.08,0,0,0,68.817,11.165c0,3.444,1.742,7.623,5.177,12.423a54.738,54.738,0,0,0,5.1,6.123.981.981,0,0,0,1.376.015,49.408,49.408,0,0,0,5.106-5.968c3.438-4.729,5.181-8.965,5.181-12.593A11.08,11.08,0,0,0,79.785,0Zm0,15.985a5.362,5.362,0,1,1,5.362-5.362A5.368,5.368,0,0,1,79.785,15.985Z'
                        transform='translate(-68.817 0)'
                        fill={colors.PRIMARY_YELLOW}
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 3,
        title: staticConstants.WALLET_MONEY,
        authenticated: true,
        path: '/wallet-money',
        isExpandable: false,
        plateForm: 'BOTH',
        svgIcon: (
            <svg xmlns='http://www.w3.org/2000/svg' width='24.968' height='22.645' viewBox='0 0 24.968 22.645'>
                <g id='Layer_2' data-name='Layer 2' transform='translate(-1.25 -2.25)'>
                    <path
                        id='Path_102'
                        data-name='Path 102'
                        d='M12.75,12.524A3.779,3.779,0,0,0,16.524,16.3h7.839V8.75H16.524A3.779,3.779,0,0,0,12.75,12.524Zm5.516,0A1.161,1.161,0,1,1,17.1,11.363,1.161,1.161,0,0,1,18.266,12.524Z'
                        transform='translate(1.855 1.048)'
                        fill={colors.PRIMARY_YELLOW}
                    />
                    <path
                        id='Path_102'
                        data-name='Path 102'
                        d='M18.379,19.089a5.516,5.516,0,1,1,0-11.032h7.839V6.6A4.361,4.361,0,0,0,21.863,2.25H5.6A4.359,4.359,0,0,0,1.25,6.6V20.54A4.359,4.359,0,0,0,5.6,24.9H21.863a4.361,4.361,0,0,0,4.355-4.355V19.089Z'
                        fill={colors.PRIMARY_YELLOW}
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 4,
        title: staticConstants.OFFER_AND_REWARDS,
        authenticated: true,
        path: '/offers',
        isExpandable: false,
        plateForm: 'BOTH',
        svgIcon: (
            <svg id='offers' xmlns='http://www.w3.org/2000/svg' width='23.069' height='23.069' viewBox='0 0 23.069 23.069'>
                <path
                    id='Path_15'
                    data-name='Path 15'
                    d='M204.988,151.811l-1.21-1.613-.673,4.709a2.04,2.04,0,0,1-.574,1.147l-8.283,8.283a3.8,3.8,0,0,1-2.708,1.121,3.864,3.864,0,0,1-.9-.105,2.478,2.478,0,0,0,2.089,1.147h9.912a2.481,2.481,0,0,0,2.478-2.478v-11.8A.676.676,0,0,0,204.988,151.811Z'
                    transform='translate(-182.054 -143.431)'
                    fill='#f6b547'
                />
                <circle id='Ellipse_7' data-name='Ellipse 7' cx='0.676' cy='0.676' r='0.676' transform='translate(13.27 9.876)' fill='#f6b547' />
                <circle id='Ellipse_8' data-name='Ellipse 8' cx='0.676' cy='0.676' r='0.676' transform='translate(5.624 9.876)' fill='#f6b547' />
                <path
                    id='Path_16'
                    data-name='Path 16'
                    d='M11.238,19.951l8.283-8.283a.676.676,0,0,0,.191-.382l.956-6.69a.676.676,0,0,0-.191-.573L19.044,2.587,17.132,4.5l.478.478a.676.676,0,1,1-.956.956L14.743,4.021a.676.676,0,1,1,.956-.956l.478.478,1.912-1.912L16.655.2a.677.677,0,0,0-.573-.191L9.391.962a.676.676,0,0,0-.382.191L.725,9.437a2.481,2.481,0,0,0,0,3.5l7.009,7.009a2.481,2.481,0,0,0,3.5,0ZM13.946,8.525a2.028,2.028,0,1,1-2.028,2.028A2.03,2.03,0,0,1,13.946,8.525ZM6.3,12.58a2.028,2.028,0,1,1,2.028-2.028A2.03,2.03,0,0,1,6.3,12.58Zm2.23,3.427a.676.676,0,0,1-.641-.89L11.075,5.56a.676.676,0,0,1,1.282.427L9.172,15.545A.676.676,0,0,1,8.531,16.007Z'
                    transform='translate(0 0)'
                    fill='#f6b547'
                />
                <path id='Path_17' data-name='Path 17' d='M403.846,1.154A.676.676,0,1,0,402.891.2l-1.434,1.434.956.956Z' transform='translate(-383.369 0)' fill='#f6b547' />
            </svg>
        ),
    },
    {
        id: 5,
        title: staticConstants.FEEDBACK,
        authenticated: true,
        path: '/feedback',
        isExpandable: false,
        plateForm: 'BOTH',
        svgIcon: (
            <svg id='feedback' xmlns='http://www.w3.org/2000/svg' width='20.376' height='20.376' viewBox='0 0 20.376 20.376'>
                <g id='Group_389' data-name='Group 389'>
                    <path
                        id='Path_101'
                        data-name='Path 101'
                        d='M18.338,0H2.038A2.028,2.028,0,0,0,.01,2.038L0,20.376,4.075,16.3H18.338a2.037,2.037,0,0,0,2.038-2.038V2.038A2.037,2.037,0,0,0,18.338,0ZM11.207,12.226H9.169V10.188h2.038v2.038Zm0-4.075H9.169V4.075h2.038V8.15Z'
                        fill='#f6b547'
                    />
                </g>
            </svg>
        ),
    },
    // {
    //     id: 6,
    //     title: staticConstants.ABOUT_US,
    //     authenticated: false,
    //     path: '/about-us',
    //     isExpandable: false,
    //     plateForm: 'ANDROID',
    //     svgIcon: (
    //         <svg xmlns='http://www.w3.org/2000/svg' width='21.594' height='21.594' viewBox='0 0 21.594 21.594'>
    //             <path
    //                 id='About_us'
    //                 d='M0,10.8A10.79,10.79,0,1,1,6.332,20.63l-4.264.8A1.62,1.62,0,0,1,.184,19.51L1.027,15.4A10.76,10.76,0,0,1,0,10.8Zm11.877,4.319V8.638a1.08,1.08,0,0,0-1.08-1.08H8.638V9.717h1.08v5.4H8.638v2.159h4.319V15.116Zm0-8.638V4.319H9.717V6.478Z'
    //                 transform='translate(0)'
    //                 fill='#f6b547'
    //                 fillRule='evenodd'
    //             />
    //         </svg>
    //     ),
    // },
   
    {
        id: 8,
        title: staticConstants.CEO_SPEAKS,
        path: '/ceo',
        authenticated: false,
        isExpandable: false,
        plateForm: 'ANDROID',
        svgIcon: (
            <svg xmlns='http://www.w3.org/2000/svg' width='21.17' height='27.234' viewBox='0 0 21.17 27.234'>
                <g id='CEO_Speak' data-name='CEO Speak' transform='translate(-63.015 -7.738)'>
                    <path
                        id='Path_777'
                        data-name='Path 777'
                        d='M165.541,19.122c2.956,0,5.249-3.3,5.249-6.133a5.25,5.25,0,1,0-10.5,0C160.29,15.824,162.583,19.122,165.541,19.122Zm0-9.5a3.372,3.372,0,0,1,3.368,3.369c0,1.914-1.554,4.252-3.368,4.252s-3.37-2.338-3.37-4.252A3.373,3.373,0,0,1,165.541,9.619Z'
                        transform='translate(-91.94)'
                        fill='#f6b547'
                    />
                    <path
                        id='Path_778'
                        data-name='Path 778'
                        d='M81.748,178.906v-3.428a6,6,0,0,0-3.029-5.209c.005.086.015.171.015.258,0,1.578-2.318,5.142-3.839,7.311l-.624-3.059.662-.662a.2.2,0,0,0,0-.286l-.257-.257a.843.843,0,0,0-.6-.247h-.96a.843.843,0,0,0-.6.247l-.257.257a.2.2,0,0,0,0,.286l.662.662-.624,3.059c-1.521-2.17-3.839-5.733-3.839-7.311,0-.087.009-.172.016-.258a6,6,0,0,0-3.029,5.21v3.428a2.524,2.524,0,0,0-2.438,2.517v4.639a2.528,2.528,0,0,0,2.525,2.526H81.659a2.529,2.529,0,0,0,2.526-2.526v-4.639A2.523,2.523,0,0,0,81.748,178.906Zm.556,7.156a.645.645,0,0,1-.645.644H65.541a.645.645,0,0,1-.644-.644v-4.639a.645.645,0,0,1,.644-.644H81.659a.645.645,0,0,1,.645.644Z'
                        transform='translate(0 -153.615)'
                        fill='#f6b547'
                    />
                    <path
                        id='Path_779'
                        data-name='Path 779'
                        d='M157.6,377.254a2.084,2.084,0,0,1,.633.1.163.163,0,0,0,.208-.115l.113-.44a.163.163,0,0,0-.105-.2,2.857,2.857,0,0,0-.892-.128,2.136,2.136,0,0,0-2.287,2.226,1.993,1.993,0,0,0,2.195,2.1,3.154,3.154,0,0,0,.941-.127.163.163,0,0,0,.109-.186l-.082-.426a.164.164,0,0,0-.2-.127,2.533,2.533,0,0,1-.623.082,1.377,1.377,0,0,1-.005-2.754Z'
                        transform='translate(-87.191 -348.507)'
                        fill='#f6b547'
                    />
                    <path
                        id='Path_780'
                        data-name='Path 780'
                        d='M227.194,380.141h1.379a.163.163,0,0,0,.163-.163v-.444a.163.163,0,0,0-.163-.163h-1.379v-.87h1.471a.163.163,0,0,0,.163-.163v-.45a.163.163,0,0,0-.163-.163h-2.26a.163.163,0,0,0-.163.163v3.863a.163.163,0,0,0,.163.163h2.347a.163.163,0,0,0,.163-.163v-.45a.163.163,0,0,0-.163-.163h-1.559Z'
                        transform='translate(-154.274 -349.691)'
                        fill='#f6b547'
                    />
                    <path
                        id='Path_781'
                        data-name='Path 781'
                        d='M285.357,376.473a2.028,2.028,0,0,0-2.038,2.195,1.953,1.953,0,0,0,1.969,2.133,2,2,0,0,0,2.059-2.207A1.953,1.953,0,0,0,285.357,376.473Zm-.018,3.575c-.635,0-1.02-.578-1.02-1.4s.373-1.424,1.013-1.424,1.014.646,1.014,1.4C286.347,379.439,285.979,380.048,285.339,380.048Z'
                        transform='translate(-208.22 -348.51)'
                        fill='#f6b547'
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 9,
        title: staticConstants.ONLINE_SUPPORT,
        path: '#',
        // path: '/online-support',
        authenticated: false,
        isExpandable: false,
        plateForm: 'ANDROID',
        svgIcon: (
            <svg xmlns='http://www.w3.org/2000/svg' width='21.417' height='27.709' viewBox='0 0 21.417 27.709'>
                <g id='Contact_Us' data-name='Contact Us' transform='translate(-15.218 -5)'>
                    <path
                        id='Path_782'
                        data-name='Path 782'
                        d='M36.467,33.916c-.62-1.845-3.091-3-4.853-3.775-.69-.3-2.6-.815-2.831-1.684a1.8,1.8,0,0,1,0-.891,1.723,1.723,0,0,1-.325.032H27.305a1.661,1.661,0,1,1,0-3.321h1.148a1.656,1.656,0,0,1,1.029.357,7.388,7.388,0,0,0,1.218-.267,10.3,10.3,0,0,0,.982-3.393c.375-4.633-2.465-7.343-6.538-6.875a5.458,5.458,0,0,0-4.921,5.391A9.79,9.79,0,0,0,22.245,26.1c.5.686,1.025,1.127.944,1.954-.094.978-1.139,1.25-1.887,1.551a21.6,21.6,0,0,0-2.291,1.146c-1.553.857-3.257,1.89-3.64,3.3-.848,3.13,2.016,4.079,4.381,4.517a35.406,35.406,0,0,0,6.2.4c3.4,0,9.527-.136,10.515-2.7A4.262,4.262,0,0,0,36.467,33.916Z'
                        transform='translate(0 -6.262)'
                        fill='#f6b547'
                    />
                    <path
                        id='Path_783'
                        data-name='Path 783'
                        d='M31.779,19.206a.867.867,0,0,0-.726-.4H29.905a.865.865,0,1,0,0,1.73h1.148a.858.858,0,0,0,.763-.468,7.763,7.763,0,0,0,3.97-1.337,1.426,1.426,0,0,0,.776.229h.072a1.443,1.443,0,0,0,1.443-1.445V14.637a1.439,1.439,0,0,0-.823-1.3,8.738,8.738,0,0,0-17.458,0,1.438,1.438,0,0,0-.823,1.3v2.884a1.443,1.443,0,0,0,1.442,1.445h.073a1.443,1.443,0,0,0,1.443-1.445V14.637a1.44,1.44,0,0,0-.8-1.291,7.407,7.407,0,0,1,14.793,0,1.441,1.441,0,0,0-.8,1.291v2.884a1.451,1.451,0,0,0,.1.536,7.068,7.068,0,0,1-3.444,1.149Z'
                        transform='translate(-2.599)'
                        fill='#f6b547'
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 10,
        title: 'Contact Us',
        path: '/contact-us',
        authenticated: false,
        isExpandable: false,
        plateForm: 'ANDROID',
        svgIcon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24.175" height="27.895" viewBox="0 0 24.175 27.895">
            <path id="phone-book" d="M20.539,18.5l1.422,2.4-.618.366a3.724,3.724,0,0,1-4.877-.969,37.365,37.365,0,0,1-2.3-3.426,37.708,37.708,0,0,1-1.9-3.66A3.727,3.727,0,0,1,13.756,8.47l.619-.366L15.8,10.5a2.79,2.79,0,0,0-.977,3.823l1.9,3.2a2.8,2.8,0,0,0,3.823.976ZM27.175,3.789V26.105a2.793,2.793,0,0,1-2.789,2.789H7.649A2.793,2.793,0,0,1,4.86,26.105v-1.86H3.93a.93.93,0,0,1,0-1.86h.93V15.877H3.93a.93.93,0,0,1,0-1.86h.93V7.509H3.93a.93.93,0,1,1,0-1.86h.93V3.789A2.793,2.793,0,0,1,7.649,1H24.386a2.793,2.793,0,0,1,2.789,2.789ZM23.561,19.954l-1.423-2.4a1.864,1.864,0,0,0-2.547-.651.931.931,0,0,1-1.275-.325l-1.9-3.2a.929.929,0,0,1,.325-1.274A1.862,1.862,0,0,0,17.4,9.556l-1.423-2.4A1.862,1.862,0,0,0,13.428,6.5l-.618.366a5.583,5.583,0,0,0-2.237,7.109,39.366,39.366,0,0,0,2,3.842,39.2,39.2,0,0,0,2.412,3.6,5.579,5.579,0,0,0,7.31,1.448l.617-.366a1.86,1.86,0,0,0,.653-2.549Z" transform="translate(-3 -1)" fill="#f6b547"/>
            </svg>

        ),
    },
    {
        id: 11,
        title: staticConstants.JOB_CAREER,
        path: '/job-and-career',
        authenticated: false,
        isExpandable: false,
        plateForm: 'ANDROID',
        svgIcon: (
            <svg id='Job_and_Career' data-name='Job and Career' xmlns='http://www.w3.org/2000/svg' width='27.175' height='27.175' viewBox='0 0 27.175 27.175'>
                <path id='Path_785' data-name='Path 785' d='M9.662,11.323a5.662,5.662,0,1,1,5.662-5.662A5.668,5.668,0,0,1,9.662,11.323Z' transform='translate(0.529)' fill='#f6b547' />
                <path
                    id='Path_786'
                    data-name='Path 786'
                    d='M5.378,12A5.383,5.383,0,0,0,0,17.381v3.963a.855.855,0,0,0,.849.849H11.323v-3.68a4.253,4.253,0,0,1,3.635-4.19,4.355,4.355,0,0,1,1.563-2.106C15.388,11.893,15.327,12,5.378,12Z'
                    transform='translate(0 1.586)'
                    fill='#f6b547'
                />
                <path
                    id='Path_787'
                    data-name='Path 787'
                    d='M23.54,16.331H22.125v-.849A1.984,1.984,0,0,0,20.143,13.5H17.313a1.984,1.984,0,0,0-1.982,1.982v.849H13.916a1.97,1.97,0,0,0-1.419.6l6.231,3.251,6.231-3.251A1.97,1.97,0,0,0,23.54,16.331Zm-6.511,0v-.849a.283.283,0,0,1,.283-.283h2.831a.283.283,0,0,1,.283.283v.849Z'
                    transform='translate(1.653 1.786)'
                    fill='#f6b547'
                />
                <path
                    id='Path_788'
                    data-name='Path 788'
                    d='M19.187,21.305a.85.85,0,0,1-.785,0L12,17.965v4.852A1.984,1.984,0,0,0,13.982,24.8h9.625a1.984,1.984,0,0,0,1.982-1.982V17.965Z'
                    transform='translate(1.588 2.377)'
                    fill='#f6b547'
                />
            </svg>
        ),
    },
    {
        id: 7,
        title: staticConstants.GET_FRANCHISE,
        authenticated: false,
        path: '/franchise',
        isExpandable: false,
        plateForm: 'ANDROID',
        svgIcon: (
            <svg id='Get_Franchisee' data-name='Get Franchisee' xmlns='http://www.w3.org/2000/svg' width='21.417' height='21.417' viewBox='0 0 21.417 21.417'>
                <g id='Glyph' transform='translate(0 0)'>
                    <path
                        id='Path_772'
                        data-name='Path 772'
                        d='M21.413,32H0v.892a1.785,1.785,0,0,0,1.785,1.785H4.462a1.785,1.785,0,0,0,1.785-1.785,1.785,1.785,0,0,0,1.785,1.785h5.354a1.785,1.785,0,0,0,1.785-1.785,1.785,1.785,0,0,0,1.785,1.785h2.677a1.785,1.785,0,0,0,1.785-1.785V32Z'
                        transform='translate(0 -24.861)'
                        fill='#f6b547'
                    />
                    <path id='Path_773' data-name='Path 773' d='M72.224,6.247l-1.6-5.6A.892.892,0,0,0,69.766,0H65.679l1.249,6.247Z' transform='translate(-51.027 0)' fill='#f6b547' />
                    <path id='Path_774' data-name='Path 774' d='M7.528,0H3.441a.892.892,0,0,0-.858.647l-1.6,5.6h5.3Z' transform='translate(-0.764 0)' fill='#f6b547' />
                    <path id='Path_775' data-name='Path 775' d='M38.376,0H34.128L32.879,6.247h6.747Z' transform='translate(-25.544 0)' fill='#f6b547' />
                    <path
                        id='Path_776'
                        data-name='Path 776'
                        d='M20.063,45.62a2.667,2.667,0,0,1-1.785-.683,2.667,2.667,0,0,1-1.785.683H11.139a2.667,2.667,0,0,1-1.785-.683,2.667,2.667,0,0,1-1.785.683H4.892A2.66,2.66,0,0,1,4,45.464v9.972a.892.892,0,0,0,.892.892H6.677V48.3a.892.892,0,0,1,.892-.892h4.462a.892.892,0,0,1,.892.892v8.031H22.74a.892.892,0,0,0,.892-.892V45.464a2.66,2.66,0,0,1-.892.156Zm.892,5.354a.892.892,0,0,1-.892.892H15.6a.892.892,0,0,1-.892-.892V48.3a.892.892,0,0,1,.892-.892h4.462a.892.892,0,0,1,.892.892Z'
                        transform='translate(-3.108 -34.912)'
                        fill='#f6b547'
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 12,
        title: staticConstants.PRIVACY_POLICY,
        path: '/privacy-policy',
        authenticated: false,
        isExpandable: false,
        plateForm: 'ANDROID',
        svgIcon: (
            <svg id='Privacy_Policy' data-name='Privacy Policy' xmlns='http://www.w3.org/2000/svg' width='23.098' height='23.098' viewBox='0 0 23.098 23.098'>
                <path
                    id='Path_789'
                    data-name='Path 789'
                    d='M13.715,0H2.647A2.645,2.645,0,0,0,0,2.647V17.564a2.645,2.645,0,0,0,2.647,2.647H10.49a8.376,8.376,0,0,1-1.347-4.523V13.339a.93.93,0,0,1-.481.135H3.85a.962.962,0,1,1,0-1.925H8.662a.939.939,0,0,1,.491.135,2.824,2.824,0,0,1,1.03-2.06H3.85a.962.962,0,1,1,0-1.925h8.662a.967.967,0,0,1,.9.606l2.743-.934a.765.765,0,0,1,.212-.058V2.647A2.645,2.645,0,0,0,13.715,0ZM7.7,5.775H3.85a.962.962,0,0,1,0-1.925H7.7a.962.962,0,1,1,0,1.925Z'
                    fill='#f6b547'
                />
                <path
                    id='Path_790'
                    data-name='Path 790'
                    d='M22.878,11.27,17.825,9.551a.966.966,0,0,0-.621,0L12.152,11.27a.964.964,0,0,0-.653.911v3.867c0,4.877,5.408,7.233,5.638,7.331a.976.976,0,0,0,.378.076.952.952,0,0,0,.377-.077c.23-.1,5.638-2.453,5.638-7.33V12.181a.961.961,0,0,0-.653-.911Zm-1.946,3.62-2.887,3.85a.958.958,0,0,1-.7.382l-.068,0a.958.958,0,0,1-.68-.282l-1.925-1.925a.962.962,0,1,1,1.361-1.361l1.14,1.14,2.221-2.962a.962.962,0,0,1,1.54,1.155Z'
                    transform='translate(-0.432 -0.357)'
                    fill='#f6b547'
                />
            </svg>
        ),
    },
    // {
    //     id: 13,
    //     title: "Nutrition Information",
    //     path: "/nutrition-information",
    //     isExpandable: false,
    //     plateForm:"ANDROID",
    //     svgIcon: <svg id="feedback" xmlns="http://www.w3.org/2000/svg" width="22.552" height="22.552" viewBox="0 0 22.552 22.552">
    //         <g id="Group_389" data-name="Group 389">
    //             <path id="Path_102" data-name="Path 102" d="M20.3,0H2.255A2.245,2.245,0,0,0,.011,2.255L0,22.552l4.51-4.51H20.3a2.255,2.255,0,0,0,2.255-2.255V2.255A2.255,2.255,0,0,0,20.3,0ZM12.4,13.531H10.148V11.276H12.4v2.255Zm0-4.51H10.148V4.51H12.4v4.51Z" fill={colors.PRIMARY_YELLOW} />
    //         </g>
    //     </svg>
    // },
    {
        id: 15,
        title: staticConstants.TERMS_AND_CONDITIONS,
        path: '/terms-and-conditions',
        authenticated: false,
        isExpandable: false,
        plateForm: 'ANDROID',
        svgIcon: (
            <svg xmlns='http://www.w3.org/2000/svg' width='21.5' height='21.5' viewBox='0 0 21.5 21.5'>
                <g id='Terms_and_Conditinos' data-name='Terms and Conditinos' transform='translate(-1.25 -1.25)'>
                    <path
                        id='Path_791'
                        data-name='Path 791'
                        d='M17,1.25H3A1.758,1.758,0,0,0,1.25,3V21A1.758,1.758,0,0,0,3,22.75H18c.11,0,.22,0,.33-.01a.171.171,0,0,1,.04-.03,6.533,6.533,0,0,0,.36-1.55,2.978,2.978,0,0,1-.73.09,3.25,3.25,0,0,1,0-6.5,3.081,3.081,0,0,1,.75.09V3A1.758,1.758,0,0,0,17,1.25Zm-11.5,4h9a.75.75,0,0,1,0,1.5h-9a.75.75,0,0,1,0-1.5Zm0,4h9a.75.75,0,0,1,0,1.5h-9a.75.75,0,0,1,0-1.5Zm5.5,9.5H5.5a.75.75,0,0,1,0-1.5H11a.75.75,0,0,1,0,1.5Zm1-4H5.5a.75.75,0,1,1,0-1.5H12a.75.75,0,0,1,0,1.5Z'
                        fill='#f6b547'
                    />
                    <path
                        id='Path_792'
                        data-name='Path 792'
                        d='M18.75,13.31a4.751,4.751,0,1,0-1.38,9.4,4.445,4.445,0,0,0,.63.04c.11,0,.22,0,.33-.01a4.751,4.751,0,0,0,.42-9.43Zm-.02,7.85a2.978,2.978,0,0,1-.73.09,3.25,3.25,0,1,1,0-6.5,3.081,3.081,0,0,1,.75.09,3.244,3.244,0,0,1-.02,6.32Z'
                        fill='#f6b547'
                    />
                    <path
                        id='Path_793'
                        data-name='Path 793'
                        d='M17.5,19.75a.748.748,0,0,1-.53-.22l-1-1A.75.75,0,1,1,17.03,17.47l.47.47,1.47-1.47A.75.75,0,1,1,20.03,17.53l-2,2A.748.748,0,0,1,17.5,19.75Z'
                        fill='#f6b547'
                    />
                </g>
            </svg>
        ),
    },

    {
        id: 16,
        title: staticConstants.SETTINGS,
        path: '/settings',
        authenticated: true,
        isExpandable: true,
        svgIcon: (
            <svg id='Layer_2' data-name='Layer 2' xmlns='http://www.w3.org/2000/svg' width='21.594' height='19.275' viewBox='0 0 21.594 19.275'>
                <path
                    id='Path_102'
                    data-name='Path 102'
                    d='M18.16,3.848A2.185,2.185,0,0,0,16.295,2.77H7.655A2.185,2.185,0,0,0,5.791,3.848l-4.32,7.481a2.185,2.185,0,0,0,0,2.185l4.32,7.452a2.185,2.185,0,0,0,1.865,1.078h8.639a2.185,2.185,0,0,0,1.865-1.078l4.32-7.481a2.185,2.185,0,0,0,0-2.185Zm-2.542,8.559a3.642,3.642,0,1,1-3.642-3.642,3.642,3.642,0,0,1,3.642,3.642Z'
                    transform='translate(-1.178 -2.77)'
                    fill={colors.PRIMARY_YELLOW}
                />
            </svg>
        ),
        subTabs: [
            {
                subTitle: staticConstants.NOTIFICATION,
                subPath: '/notifications',
                plateForm: 'BOTH',
            },
            {
                subTitle: staticConstants.NOTIFICATION_SETTINGS,
                subPath: '/notifications-setting',
                plateForm: 'BOTH',
            },
            {
                subTitle: staticConstants.ADDRESS,
                subPath: '/address',
                plateForm: 'BOTH',
            },
            // {
            //     subTitle: "About Us",
            //     subPath: "/about-us",
            //     plateForm:"ANDROID"
            // },
            // {
            //     subTitle: "Franchisee",
            //     subPath: "/franchise",
            //     plateForm:"ANDROID"
            // },
            // {
            //     subTitle: "CEO Speak",
            //     subPath: "/ceo",
            //     plateForm:"ANDROID"
            // },
            // {
            //     subTitle: "Help And Support",
            //     subPath: "/online-support",
            //     plateForm:"ANDROID"
            // },
            // {
            //     subTitle: "Contact Us",
            //     subPath: "/contact-us",
            //     plateForm:"ANDROID"
            // },
            // {
            //     subTitle: "Job And Career",
            //     subPath: "/job-and-career",
            //     plateForm:"ANDROID"
            // },
            // {
            //     subTitle: "Nutrition Information",
            //     subPath: "/nutrition-information",
            //     plateForm:"ANDROID"
            // },
            // {
            //     subTitle: "Privacy Policy",
            //     subPath: "/privacy-policy",
            //     plateForm:"ANDROID"
            // },
            // {
            //     subTitle: "Terms And Conditions",
            //     subPath: "/terms-and-conditions",
            //     plateForm:"ANDROID"
            // },
            {
                subTitle: staticConstants.DELETE_ACCOUNT,
                subPath: '#',
                plateForm: 'BOTH',
            },
        ],
    },
];
