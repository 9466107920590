var minWindowEle = null;
const handleZohoChat = () => {
  window.$zoho.salesiq.floatwindow.visible("show");

  function minWindowClick() {
    minWindowEle.removeEventListener("click", minWindowClick);
    document.getElementById("zsiq_float").style.display = "none";
  }

  var iframe = null;
  const _interval = setInterval(() => {
    iframe = document.getElementById("siqiframe");
    if (iframe) {
      minWindowEle = iframe.contentWindow.document.getElementById("min_window");
      if (minWindowEle) {
        minWindowEle.addEventListener("click", minWindowClick);
        clearInterval(_interval);
      }
    }
  }, 1000);
  
};

export const initializeZohoChat = () => {
  if (window.$zoho) {
    document.getElementById("zsiq_float").style.display = "block !important";
    handleZohoChat();
    document.getElementsByClassName("zls-sptwndw ")[0].style.visibility =
      "visible !important";
    document.getElementsByClassName("zls-sptwndw ")[0].style.display =
      "block !important";
  } else {
    const url = "https://salesiq.zoho.in/widget";
    const widgetCode =
      "2f5303cc0c0723b4335b9629bb1cd999b975f840fed509a252853f86f314c8cc1e3c9f72231407fc47bf8a330a5f28a2";
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");

    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;
    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    window.$zoho.salesiq.ready = function () {
      window.$zoho.salesiq.floatwindow.visible("show");
      handleZohoChat();
    };
  }
};
