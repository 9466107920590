import React, { useContext, useEffect, useRef, useState } from "react";
import textUtils from "../../utils/TextUtils";
import { toast } from "react-hot-toast";
import { callPostApi } from "../../api/ApiCaller";
import * as ApiConst from "../../api/ApiConst";
import dateUtilities from "../../utils/DateUtilities";
import { NO_ERROR } from "../../api/constants";
import { GlobalContext } from "../../reducer/context";
import { EDIT_PROFILE, TOGGLE_EDIT_PROFILE_MODAL } from "../../reducer/actions/userActions";
import * as clevertapEvents from "../../clevertapEvents";
import { confirmAlert } from "react-confirm-alert";
import { initialUserDetail } from "../../reducer/initialStates/initialStates";
import * as staticConstants from "../../translations/en/en";
import { InformationAlert } from "./InformationAlert";
import { useNavigate } from "react-router-dom";
import LazyLoadedImage from "./LazyLoadedImage";

export const EditProfile = () => {
    const { authState, dispatchAuth } = useContext(GlobalContext);
    const [profileData, setProfileData] = useState({ fileName: "", fileBase64: "" });
    const [userDetails, setUserDetails] = useState({ ...initialUserDetail });
    const [errors, setErrors] = useState({});

    const fileRef = useRef(null);
    const navigate = useNavigate()
    const { user, isAuthenticated, showEditProfileModal } = authState

    useEffect(() => {
        if (isAuthenticated && user) {
            setUserDetails(user);
        }
    }, [isAuthenticated, user]);

    const handleUserDetailsChange = (event) => {
        const { name, value } = event.target
        if (name === "mobile") {
            if (value.length > 10) return;
        }
        setUserDetails((prev) => ({ ...prev, [name]: value }));
        setErrors({ [name]: "" });
    };
    const handleProfileImageClick = (event) => {
        fileRef.current.click();
    };

    // const handleProfileImageChange = (fileEvent) => {
    //     const { files } = fileEvent.target;

    //     if (files && files[0]) {
    //         const fileSizeInMB = files[0].size / (1024 * 1024); // Convert bytes to megabytes

    //         if (fileSizeInMB > 1) {
    //             // File size exceeds 1 MB, show an error or take appropriate action
    //             setErrors({ userImage: staticConstants.PROFILE_PICTURE_SIZE_SHOULD_BE_LESS_THAN_1_MB });
    //             return;
    //         }

    //         var reader = new FileReader();
    //         reader.onload = function (event) {
    //             const { result } = event.target;
    //             fileRef.current.setAttribute("src", result);
    //             setProfileData({
    //                 fileName: files[0].name,
    //                 fileBase64: result,
    //             });
    //             setUserDetails((prev) => ({ ...prev, userImage: result }));
    //             setErrors({});
    //         };

    //         reader.readAsDataURL(files[0]);
    //     }
    // };

    const handleProfileImageChange = (fileEvent) => {
        const { files } = fileEvent.target;
    
        if (files && files[0]) {
            const maxSizeInBytes = (1024 * 1024)/2; // 0.5 MB
            const fileSizeInBytes = files[0].size;
    
            if (fileSizeInBytes > maxSizeInBytes) {
                // File size exceeds 0.5 MB, compress the image
                compressImage(files[0]);
            } else {
                // File size is within the limit, proceed without compression
                setProfileDataWithoutCompression(files[0]);
            }
        }
    };
    
    const compressImage = (file) => {
        const reader = new FileReader();
    
        reader.onload = function (event) {
            const img = new Image();
            img.src = event.target.result;
    
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
    
                // Calculate the new width and height to maintain the aspect ratio
                const maxWidth = 800; // Adjust as needed
                const maxHeight = 800; // Adjust as needed
                let newWidth = img.width;
                let newHeight = img.height;
    
                if (img.width > maxWidth) {
                    newWidth = maxWidth;
                    newHeight = (img.height * maxWidth) / img.width;
                }
    
                if (img.height > maxHeight) {
                    newHeight = maxHeight;
                    newWidth = (img.width * maxHeight) / img.height;
                }
    
                canvas.width = newWidth;
                canvas.height = newHeight;
    
                // Draw the image onto the canvas with the new dimensions
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
    
                // Convert the compressed image to a base64 string
                const compressedBase64 = canvas.toDataURL('image/jpeg', 0.9); // Adjust the quality as needed
    
                // Set the profile data with the compressed image
                setProfileData({
                    fileName: file.name,
                    fileBase64: compressedBase64,
                });
    
                setUserDetails((prev) => ({ ...prev, userImage: compressedBase64 }));
                setErrors({});
            };
        };
    
        reader.readAsDataURL(file);
    };
    
    const setProfileDataWithoutCompression = (file) => {
        const reader = new FileReader();
    
        reader.onload = function (event) {
            const { result } = event.target;
            fileRef.current.setAttribute("src", result);
            setProfileData({
                fileName: file.name,
                fileBase64: result,
            });
            setUserDetails((prev) => ({ ...prev, userImage: result }));
            setErrors({});
        };
    
        reader.readAsDataURL(file);
    };
    


    const validateUserDetails = () => {
        // if (!userDetails.userImage) {
        //     setErrors({ userImage: staticConstants.PLEASE_SELECT_PROFILE_IMAGE });
        //     return false;
        // }
        if (!userDetails.fullName) {
            setErrors({ fullName: staticConstants.PLEASE_ENTER_FULL_NAME });
            return false;
        }
        if (!textUtils.isMobile(userDetails.mobile)) {
            setErrors({ mobile: staticConstants.PLEASE_ENTER_VALID_MOBILE_NUMBER });
            return false;
        }
        if (!textUtils.isEmail(userDetails.email)) {
            setErrors({ email: staticConstants.PLEASE_ENTER_VALID_EMAIL });
            return false;
        }
        if (!userDetails.dateOfBirth) {
            setErrors({ dateOfBirth: staticConstants.PLEASE_SELECT_DOB });
            return false;
        }

        if (!userDetails.gender) {
            setErrors({ gender: staticConstants.PLEASE_SELECT_GENDER });
            return false;
        }
        setErrors({});
        return true;
    };

    const updateUserDetails = (rObj, toastId) => {
        callPostApi(ApiConst.Update_Profile, rObj, {}, (response) => {
            toast.dismiss(toastId)
            if (response.errorCode === NO_ERROR) {
                showUpdateSuccessAlert()
                dispatchAuth({
                    type: EDIT_PROFILE,
                    payload: response.responsePacket,
                });
                clevertapEvents.profileEvent(response.responsePacket);
                if (showEditProfileModal) {
                    dispatchAuth({ type: TOGGLE_EDIT_PROFILE_MODAL })
                    navigate("/", { replace: true })
                }
            } else {
                InformationAlert({ message: response.message })
            }
        });
    };

    const showUpdateSuccessAlert = () => {
        confirmAlert({
            message: <div className=" text-center">
                <div className=" pb-3">
                    <img src={userDetails.userImage ? userDetails.userImage : "../img/default-user.png"} alt="profile" style={{ width: "70px", height: " 70px", borderRadius: "50%", objectFit: "cover" }} />
                </div>
                <p className="mb-0 font-size-16 font-weight-700 pb-3">
                    {staticConstants.PROFILE_UPDATED_SUCCESSFULLY}
                </p>
            </div>,
            buttons: [{ label: staticConstants.OK }],
        });
    }

    const handleUpdateProfile = (e) => {
        e.preventDefault();
        if (validateUserDetails()) {
            const toastId = toast.loading(staticConstants.PLEASE_WAIT);
            const { anniversaryDate, dateOfBirth, userImage, ...restUserDetails } = userDetails
            const dob = new Date(dateOfBirth);
            const anniversaryDateObj = new Date(dateOfBirth);
            const dobTimeStamp = dob.getTime();
            const anniversaryTimeStamp = anniversaryDateObj.getTime();
            let rObj = {
                ...restUserDetails,
                anniversarydate: anniversaryTimeStamp,
                createcustomeronpaymentgateway: false,
                dateOfBirth: dobTimeStamp,
                userImageUrl: userImage
            };
            if (profileData.fileBase64) {
                callPostApi(ApiConst.UploadFile_POST, profileData, {}, (fileResponse) => {
                    if (fileResponse.errorCode === NO_ERROR) {
                        setProfileData({ fileName: "", fileBase64: "" });
                        rObj.userImageUrl = fileResponse.responsePacket
                        updateUserDetails(rObj, toastId);
                    } else {
                        toast.dismiss(toastId)
                        InformationAlert({ message: fileResponse.message })
                    }
                }
                );
            } else {
                updateUserDetails(rObj, toastId);
            }
        }
    };

    return (
        <>
            <div className="">
                <div>
                    <div className="text-center d-flex justify-content-center align-items-center">
                        <div
                            onClick={handleProfileImageClick}
                            className="profile-box"
                        >
                            {/* <img src={userDetails.userImage ? userDetails.userImage : "../img/default-user.png"} className="profile-pic mx-auto" alt="Profile" /> */}
                            <LazyLoadedImage
                                src={userDetails.userImage ? userDetails.userImage : "../img/default-user.png"}
                                placeholderSrc="../img/default-user.png"
                                alt="user"
                                wrapperClassName="image-holder w-100"
                                className="profile-pic mx-auto"
                            />
                            <input
                                type="file"
                                onChange={handleProfileImageChange}
                                name="userProfile"
                                id="fileElement"
                                ref={fileRef}
                                className="d-none"
                            />
                            {errors.userImage && (<span style={{ color: "#cf000f" }}>{errors.userImage}</span>)}
                        </div>
                    </div>
                    <div>
                        <label
                            className="mb-0 text-secondary font-size-12 font-weight-700"
                            htmlFor="inputAddress"
                        >
                            {staticConstants.NAME}
                        </label>
                        <input
                            type="text"
                            className="form-control table-form mb-2 "
                            id="inputAddress"
                            name="fullName"
                            value={userDetails.fullName}
                            onChange={handleUserDetailsChange}
                        />
                        {errors.fullName && (<span style={{ color: "#cf000f" }}>{errors.fullName}</span>)}
                    </div>
                    <div>
                        <label
                            className="mb-0 text-secondary font-size-12 font-weight-700"
                            htmlFor="inputAddress2"
                        >
                            {staticConstants.MOBILE_NUMBER}
                        </label>
                        <input
                            type="text"
                            className="form-control table-form mb-2"
                            id="inputAddress2"
                            name="mobile"
                            value={userDetails.mobile}
                            onChange={handleUserDetailsChange}
                        />
                        {errors.mobile && (<span style={{ color: "#cf000f" }}>{errors.mobile}</span>)}
                    </div>
                    <div>
                        <label
                            className="mb-0 text-secondary font-size-12 font-weight-700"
                            htmlFor="inputAddress2"
                        >
                            {staticConstants.EMAIL_ADDRESS}
                        </label>
                        <input
                            type="email"
                            className="form-control table-form mb-2"
                            id="inputAddress2"
                            name="email"
                            value={userDetails.email}
                            onChange={handleUserDetailsChange}
                        />
                        {errors.email && (<span style={{ color: "#cf000f" }}>{errors.email}</span>)}
                    </div>
                    <div>
                        <label
                            className="mb-0 text-secondary font-size-12 font-weight-700"
                            htmlFor="inputEmail4"
                        >
                            {staticConstants.DATE_OF_BIRTH} {"{"}DD/MM/YYYY{"}"}
                        </label>
                        <input
                            type="date"
                            className="form-control table-form mb-2"
                            name="dateOfBirth"
                            value={
                                userDetails.dateOfBirth ?
                                    dateUtilities.formattedDate(
                                        userDetails.dateOfBirth,
                                        "yyyy-MM-dd"
                                    ) : ""
                            }
                            onChange={handleUserDetailsChange}
                        />
                        {errors.dateOfBirth && (<span style={{ color: "#cf000f" }}>{errors.dateOfBirth}</span>)}
                    </div>
                    <div>
                        <label
                            className="mb-0 text-secondary font-size-12 font-weight-700"
                            htmlFor="inputEmail4"
                        >
                            {staticConstants.ANNIVERSARY_DATE} {"{"}DD/MM/YYYY{"}"}
                        </label>
                        <input
                            type="date"
                            className="form-control table-form mb-2"
                            name="anniversaryDate"
                            value={
                                userDetails.anniversaryDate ?
                                    dateUtilities.formattedDate(
                                        userDetails.anniversaryDate,
                                        "yyyy-MM-dd"
                                    ) : ""
                            }
                            onChange={handleUserDetailsChange}
                        />
                        {errors.anniversaryDate && (<span style={{ color: "#cf000f" }}>{errors.anniversaryDate}</span>)}
                    </div>
                    <fieldset className="form-group">
                        <div className="row justify-content-center align-items-center mx-auto">
                            <div className="col-3 pl-0">
                                <legend className="col-form-label pb-0   font-weight-800 font-size-16 text-dark-gray float-sm-left pt-0">
                                    {staticConstants.GENDER}:
                                </legend>
                            </div>
                            <div className="col-3 cursor-pointer">
                                <div className="custom-control px-0 custom-radio">
                                    <input
                                        className="custom-control-input h-0 w-0"
                                        type="radio"
                                        name="gender"
                                        id="genderMale"
                                        value={userDetails.gender}
                                        onChange={() =>
                                            setUserDetails({
                                                ...userDetails,
                                                gender: "MALE",
                                            })
                                        }
                                        checked={userDetails.gender === "MALE"}
                                    />
                                    <label
                                        className="custom-control-label font-size-14 font-weight-600 w-100 p-0 cursor-pointer line-height-24"
                                        htmlFor="genderMale"
                                    >
                                        {staticConstants.MALE}
                                    </label>
                                </div>
                            </div>
                            <div className="col-3 cursor-pointer">
                                <div className="custom-control px-0 custom-radio">
                                    <input
                                        className="custom-control-input h-0 w-0"
                                        type="radio"
                                        name="gender"
                                        id="genderFemale"
                                        value={userDetails.gender}
                                        onChange={() =>
                                            setUserDetails({
                                                ...userDetails,
                                                gender: "FEMALE",
                                            })
                                        }
                                        checked={userDetails.gender === "FEMALE"}
                                    />
                                    <label
                                        className="custom-control-label font-size-14 font-weight-600  w-100 p-0 cursor-pointer line-height-24"
                                        htmlFor="genderFemale"
                                    >
                                        {staticConstants.FEMALE}
                                    </label>
                                </div>
                            </div>
                            <div className="col-3 cursor-pointer">
                                <div className="custom-control px-0 custom-radio">
                                    <input
                                        className="custom-control-input h-0 w-0"
                                        type="radio"
                                        name="gender"
                                        id="genderOther"
                                        value={userDetails.gender}
                                        onChange={() =>
                                            setUserDetails({
                                                ...userDetails,
                                                gender: "other",
                                            })
                                        }
                                        checked={userDetails.gender === "other"}
                                    />
                                    <label
                                        className="custom-control-label font-size-14 font-weight-600  w-100 p-0 cursor-pointer line-height-24"
                                        htmlFor="genderOther"
                                    >
                                        {staticConstants.OTHER}
                                    </label>
                                </div>
                            </div>
                        </div>
                        {errors.gender && (<span style={{ color: "#cf000f" }}>{errors.gender}</span>)}
                    </fieldset>
                    <button
                        onClick={handleUpdateProfile}
                        className="btn bg-yellow text-black custom-btn w-100"
                    >
                        {staticConstants.UPDATE_PROFILE}
                    </button>
                </div>
            </div>
        </>
    );
};

export default EditProfile;
