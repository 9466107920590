import React from 'react'
import { confirmAlert } from 'react-confirm-alert';

// default values are for login
export const AlertBox = ({ 
    heading, 
    message, 
    handleSuccess, 
    successButton, 
    handleDrop = null, 
    dropButton = "CANCEL"
}) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            const handleSuccessCall = () => {
                handleSuccess()
                onClose()
            }
            const handleDropCall = () => {
                handleDrop && handleDrop()
                onClose()
            }
            return (
                <div className=' text-center p-3 rounded shadow bg-white modal-dialog'>
                    <h5 className="font-weight-600 font-size-20 mt-3">{heading}</h5>
                    <div className='font-size-14 text-gray font-weight-700 mb-0' dangerouslySetInnerHTML={{ __html: message }}></div>
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <button 
                            onClick={handleSuccessCall} 
                            className="btn border-yellow text-yellow font-weight-800 font-size-14 border-radius-5 py-2" 
                            style={{width: "120px"}}
                        >
                            {successButton}
                        </button>
                        <button 
                            onClick={handleDropCall} 
                            className="btn bg-yellow font-weight-800 font-size-14 border-radius-5 mx-2 px-4 py-2" 
                            style={{width: "120px"}}
                        >
                            {dropButton}
                        </button>
                    </div>
                </div>
            );
        }
    });
}

export default AlertBox