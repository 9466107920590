import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import './App.css';
import RouteConfig from './RouteConfig';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ClevertapReact from 'clevertap-react';
import * as colors from './utils/colorCodes';
import { initializeAirBridge, sendCleverTapId } from './components/airbridge/AirBridgeUtilities';
import { AppContextWrapper } from './components/common';
import * as clevertapEvents from './clevertapEvents';
import Cookies from 'universal-cookie';
import { APP_VERSION_NUMBER } from './translations/en/en';

ClevertapReact.initialize('R5Z-948-885Z');
window.clevertap.spa = true
// ClevertapReact.initialize("TEST-WR5-9ZR-W95Z");
const toastDefaultOptionsStyle = {
    borderRadius: '5px',
    background: '#000',
    color: colors.WHITE,
};

function App() {
    const cookies = new Cookies();
    useEffect(() => {
        initializeAirBridge();
        setTimeout(() => {
            if (window.clevertap) {
                clevertapEvents.webAppLaunchedEvent();
                const cleverTapId = window.clevertap.getCleverTapID();
                if (cleverTapId) {
                    sendCleverTapId(cleverTapId);
                }
            }
        }, 5000);
        if (!cookies.get('PYRAMID_VERSION') || Number(cookies.get('PYRAMID_VERSION')) !== APP_VERSION_NUMBER) {
            cookies.set('PYRAMID_VERSION', APP_VERSION_NUMBER, { path: '/' });
            window.location.reload(true);
        }
    }, []);

    return (
        <>
            <AppContextWrapper>
                <RouteConfig />
            </AppContextWrapper>
            <Toaster
                toastOptions={{
                    className: '',
                    duration: 3000,
                    style: toastDefaultOptionsStyle,
                    success: {
                        duration: 3000,
                        theme: {
                            primary: colors.TOAST_PRIMARY_GREEN,
                            secondary: colors.TOAST_SECONDARY_BLACK,
                        },
                    },
                }}
            />
        </>
    );
}

export default App;
