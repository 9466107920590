
import Modal from 'react-modal';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export const ModalBox = (props) => {
  const { headerTitle, show, toggleModel, children, modalFlag, modalClass, shouldCloseOnOverlayClick } = props
  const overlyObject = { backgroundColor: "#00000096" }
  const loginZIndex = { zIndex: "1200", backgroundColor: "#00000096" }
  
  const closeModal = () => {
    toggleModel()
    const isClassExist = document.body.classList.contains('ReactModal__Body--open');
    if (isClassExist) {
      document.body.classList.remove('ReactModal__Body--open');
    }
  }

  return (
    <>
      <Modal
        className={"modal-dialog modal-dialog-centered modalBox"}
        isOpen={show}
        contentLabel="onRequestClose Example"
        onRequestClose={shouldCloseOnOverlayClick ? null : closeModal}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick ? shouldCloseOnOverlayClick : true}
        style={{ overlay: modalClass ? loginZIndex : overlyObject }}
      >
        <div className="modal-content">
          <div style={{ position: "relative" }} className={`modal-header justify-content-center ${modalFlag && modalFlag === "LOGIN" ? "custom-login-header" : ""}`}>
            <h5 className="modal-title" dangerouslySetInnerHTML={{ __html: headerTitle }}></h5>
            {
              !shouldCloseOnOverlayClick &&
              <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            }
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalBox;