import React from 'react'
import { LoaderIcon } from 'react-hot-toast';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';

const imageLoaderIcon = <div className="image-holder d-flex justify-content-center align-items-center w-100"><LoaderIcon /></div>
export const LazyLoadedImage = ({ placeholder, placeholderSrc, src, alt, wrapperClassName, ...props }) => {

    const getPlaceholderSrc = () => {
        if (src.startsWith === "https" || src.startsWith === "http") {
            return src.replace("primary-image", "thumbnail-image")
        }else{
            return src
        }

    }
    return (
        <>
            <LazyLoadImage
                placeholder={placeholder}
                effect="blur"
                placeholderSrc={placeholderSrc || getPlaceholderSrc()}
                src={src}
                wrapperClassName={wrapperClassName}
                alt={alt}
                {...props}
            />
        </>
    )
}

LazyLoadedImage.defaultProps = {
    alt: "pyramid-item",
    wrapperClassName: "",
    src: "",
    placeholderSrc: "",
    placeholder: imageLoaderIcon
};

export default LazyLoadedImage
