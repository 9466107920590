// // Live Server With No SSL
// export const HTTPS = "http";
// export const WSS = "ws";
// export const HOST = "15.206.119.146";
// export const PORT = "80";
// export const SERVICE = "";

// // Live Server With SSL
export const HTTPS = "https";
export const WSS = "wss";
export const HOST = "pyramidcafe.com";
export const PORT = "443";
export const SERVICE = "";

// http://www.restaurant.kpis.in/v1/api'
export const BASE_URL = `${HTTPS}://${HOST}:${PORT}`;

// Baser url for API
export const BASE_URL_V1 = BASE_URL + "/v1";
export const BASE_URL_API = BASE_URL_V1 + "/api";
// export const BASE_URL_API_Guest = BASE_URL + "api/guest/";
// export const BASE_URL_API_Player = BASE_URL + "api/player/";

// Login
export const Generate_Otp = BASE_URL_API + "/generateOtpForUser";
export const Verify_Otp = BASE_URL_API + "/loginUserByOtp";
export const Login_User_by_FaceBook = BASE_URL_API + "/loginUserByFacebook";
export const Otp_less_login = BASE_URL_API + "/otpLessLogin";
export const Login_With_Otp_less_Whatsapp = BASE_URL_API + "/loginUserByWhatsApp";

// Get Banner List
export const Get_AdBanner_List = BASE_URL_API + "/getAdBannerList";

// Update Profile 
export const Get_Profile_Details = BASE_URL_API + "/profileDetail"
export const Update_Profile = BASE_URL_API + "/updateProfile"

// Customer Address
export const Get_Customer_Address_list = BASE_URL_API + "/getAddressList"
export const Delete_Customer_Address = BASE_URL_API + "/deleteAddress/recordId"
export const Add_Customer_Address = BASE_URL_API + "/saveAddress"

// Customer Order
export const Get_Customer_Order_List = BASE_URL_API + "/getOrderList"
export const Get_Customer_Order_Details = BASE_URL_API + "/orderDetail/{orderReferenceId}"

// Customer Notification
export const Get_Customer_Notification_List = BASE_URL_API + "/getNotificationList"
export const Get_Alert_Notification_Setting_List = BASE_URL_API + "/getAlertNotificationSetting"
export const Update_Alert_Notification_Setting = BASE_URL_API + "/updateAlertNotificationSetting"

// Customer Feedback
export const Post_customer_Feedback = BASE_URL_API + "/saveFeedback"

// Customer Wallet
export const Get_Reward_Point_And_Wallet_Balance = BASE_URL_API + "/getRewardPointAndWalletBalance"
export const Apply_Coupon = BASE_URL_API + "/applyCoupon"
export const Get_Wallet_Transaction_List_Data = BASE_URL_API + "/getWalletTransactionListData"
export const Get_Reward_Transaction_List_Data = BASE_URL_API + "/getRewardTransactionListData"
export const Post_Redeem_Voucher = BASE_URL_API + "/redeemVoucher"

// Scratch Card
export const Get_All_Scratch_Card = BASE_URL_API + "/getAllScratchCard"
export const Scratch_The_Card = BASE_URL_API + "/scratchTheCard/{scratchCardId}"
export const Get_COUPON_LIST = BASE_URL_API + "/couponList/ALL/ALL"

// Static Pages
export const Static_Page_Url = BASE_URL+"/staticPage"

// Job And Career
export const Post_Job_And_Career = BASE_URL_API + "/jobAndCareer"

// Contact Us/ Get In Touch
export const GetIn_Touch = BASE_URL_API + "/getInTouch"

// Book a Table
export const Table_Reservation_Occasion_List = BASE_URL_API + "/tableReservationOccasionList"
export const Save_Reservation = BASE_URL_API + "/saveReservation"

// Upload File
export const UploadFile_POST = BASE_URL_API + "/uploadFileInBase64";

// Common Order APIs
export const Post_Restaurant_List = BASE_URL_API + "/restaurantList"
export const Post_GetItemListWithCatSubCat = BASE_URL_API + "/getItemListWithCatSubCat"
export const Post_SaveOrder = BASE_URL_API + "/saveOrder";
export const Post_OrderDetail = BASE_URL_API + "/orderDetail/{orderRefId}";
export const Post_PlaceOrder = BASE_URL_API + "/placeOrder/{orderRefId}";
export const Post_Restaurant_Detail = BASE_URL_API + "/getRestaurantDetail/{restaurantUuid}";
export const Post_Complete_Post_PaidOrder = BASE_URL_API + "/completePostPaidOrder/{orderRefId}";

// Dine In
export const Get_ScanQrCode = BASE_URL_API + "/scanQrCode/{QrCodeString}";
export const Get_GetMyRunningOrderV2 = BASE_URL_API + "/getMyRunningOrderV2"
export const Post_CompletePostPaidOrder = BASE_URL_API + "/completePostPaidOrder"

// Events 
export const Get_Upcoming_Event_List = BASE_URL_API + "/getEventList/upComing";
export const Get_Past_Event_List = BASE_URL_API + "/getEventList/past";
export const Get_Event_Details = BASE_URL_API + "/getEventDetail/{eventId}";
export const Post_Save_Reservation = BASE_URL_API + "/saveReservation";
export const Get_Event_Ticket_List = BASE_URL_API + "/getEventTicketList/{eventId}";
export const Post_Pay_For_Event_Reservation = BASE_URL_API + "/payForEventReservation";

// Steal Deals
export const Get_Steal_Deal_Category_List = BASE_URL_API + "/getStealDealCategoryList";
export const Get_Steal_Deal_Item_List = BASE_URL_API + "/getStealDealItemList";
export const Get_Steal_Deal_Details = BASE_URL_API + "/getStealDealItemDetail/{itemId}";
export const Get_Steal_Deal_Reservation_Item_List = BASE_URL_API + "/stealDealReservationItemItemList";
export const Get_Steal_Deal_Apply_Code = BASE_URL_API + "/applyGiftCode/{giftCode}";
export const Post_Steal_Deal_Generate_Gift_Code = BASE_URL_API + "/generateGiftCode";
export const Post_Steal_Deal_Order = BASE_URL_API + "/stealDealOrder";
export const Post_Calculate_Taxes_On_StealDealItems = BASE_URL_API + "/calculateTaxesOnStealDealItems";
export const Post_Reserve_StealDeal_Items = BASE_URL_API + "/reserveStealDealItems";

// Checkout APIs
export const Get_Update_OrderItem_Quantity = BASE_URL_API + "/updateOrderItemQuantity/{orderRefId}/{OrderItemId}/{type}";
export const GET_CouponList = BASE_URL_API + "/couponList/{orderType}/{restaurantId}";
export const Get_Remove_Coupon = BASE_URL_API + "/removeCoupon/{orderRefId}"
export const Post_Update_Address = BASE_URL_API + "/updateAddress"
export const Post_GetDeliveryOffer = BASE_URL_API + "/getDeliveryOffer"
export const Post_Update_Instruction_On_OrderItem = BASE_URL_API + "/updateInstructionOnOrderItem/{orderRefId}/{OrderItemId}"

// Profile
export const Post_PayTipOnOrder = BASE_URL_API + "/payTipOnOrder/{orderRefId}"
export const Post_PayTipOnStealDealAndDineIn = BASE_URL_API + "/payTipOnStealDealOrder/{orderRefId}"
export const Post_PayAtRestaurant = BASE_URL_API + "/payAtRestaurant"
export const Post_RateStealDealOrderByCustomer = BASE_URL_API + "/rateStealDealOrderByCustomer/{orderRefId}"
export const Post_RateOrderByCustomer = BASE_URL_API + "/rateOrderByCustomer/{orderRefId}"
export const Post_SetLastPaymentMode = BASE_URL_API + "/setLastPaymentMode"
export const Get_GetLastPaymentMode = BASE_URL_API + "/getLastPaymentMode"
export const Get_Delete_Account = BASE_URL_API + "/deleteAccount"

// Payment Request
export const Get_Payment_Gateway_List = BASE_URL_API+"/getPaymentGatewayList/{orderType}"
export const Post_Generate_Payment_Request_With_Order = BASE_URL_API + "/generatePaymentRequestWithOrder"
export const Post_PG_Redirect = BASE_URL_API + "/paytm/pgRedirect/{txnId}"
export const Post_Generate_Payment_Request = BASE_URL_API + "/generatePaymentRequest"
export const Post_Initiate_Transaction = BASE_URL_API + "/paytm/initiateTransaction/{txnId}"
export const Post_Update_Payment_Request = BASE_URL_API + "/updatePaymentRequest/{txnId}"

export const Post_Update_Device_Detail = BASE_URL_API + "/updateDeviceDetail"
export const Get_Get_Last_Order_WhichWasNotRated = BASE_URL_API + "/getLastOrderWhichWasNotRated"

export const Get_DriverLocation = BASE_URL_API + "/getDriverLocation/{orderRefId}"

// getLastPaymentMode
// setLastPaymentMode 
// completePostPaidOrder 
// updateDeviceDetail
// getLastOrderWhichWasNotRated